import { useState, useEffect } from 'react';
import { ListGroup, Alert } from 'react-bootstrap';
import useFetch from '../../custom_hooks/useFetch';

const AssignmentDisplay = ({ videoData }) => {
  const [assignmentQuestions, setAssignmentQuestions] = useState([]);
  
  const { result, isResultPending, resultError } = useFetch(
    `https://opecstat.com/opecstat_server/assignment/getAssignmentByVideoId/${videoData._id}`
  );

  useEffect(() => {
    if (!isResultPending && result) {
      setAssignmentQuestions(result.questions || []);
    }
  }, [result, isResultPending]);

  return (
    <div style={{ padding: "5px", color: "black" }}>
      <h5>Take Home Assignments</h5>
      {isResultPending ? (
        <Alert variant="info">Loading assignment questions...</Alert>
      ) : resultError ? (
        <Alert variant="warning">No assignment questions available...</Alert>
      ) : assignmentQuestions.length ? (
        <ListGroup>
          {assignmentQuestions.map((question, index) => (
            <ListGroup.Item key={index}>{`${index + 1}. ${question}`}</ListGroup.Item>
          ))}
        </ListGroup>
      ) : (
        <Alert variant="warning">No assignments available for this video.</Alert>
      )}
    </div>
  );
};

export default AssignmentDisplay;
