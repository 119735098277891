import React, { useState, useEffect, useCallback } from 'react';
import "../assets/fonts/fontawesome-free/css/all.min.css";
import "../assets/css/sb-admin-2.min.css";
import Sidebar from "../components/userDashboard/Sidebar";
import Topbar from "../components/userDashboard/Topbar";
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import loader1 from '../assets/images/03-42-11-849_512.gif';
import OnboardForm from '../components/userDashboard/OnboardForm';
import WebinarView from '../components/userDashboard/WebinarView';
import PageUnderCreation from '../components/userDashboard/utilityComponents/PageUnderCreation';
import CreateSwapForm from '../components/userDashboard/CreateSwap';
import ViewSwapOffers from '../components/userDashboard/ViewOffers';

const Webinar = () => {
    //const [userData, setUserData] = useState(null);
    const [currentView, setCurrentView] = useState("home");
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [videoData, setVideoData] = useState(null);
    const navigate = useNavigate();
    const { id } = useParams();

    // Function to check if the user is enrolled in the required course
const checkEnrollment = (userData, videoData) => {
    //console.log("Courses ", JSON.stringify(userData.cur_courses_id, null, 4));
    if (!userData || !videoData) return false;

    // Check if the user is enrolled in the main course or combined courses
    for (const course of userData.cur_courses_id) {
        //console.log("Inner Course: ", course.course_combine_with);
        //console.log("Main Course ID: ", videoData.main_course_id);

        // Check if the user is enrolled in the main course
        if (course._id === videoData.main_course_id._id) {
            return true;
        }

        // Check if the user is enrolled in any of the combined courses
        if (course.course_combine_with && course.course_combine_with.some(combinedCourse => combinedCourse._id === videoData.main_course_id._id)) {
            return true; // If a match is found, return true and stop checking
        }
    }

    return false; // If no match is found, return false
};


    const checkVideo = useCallback(async () => {
        try {
            const response = await axios.post('https://www.opecstat.com/opecstat_server/video/checkVideoExist', { video: id }, { headers: { 'Content-Type': 'application/json' } });
            const data = response.data;

            if (data.exists) {
                if(id === "66e7044fbf363318f77c43b5" || id === "66e704d9c6f11fc4058584a9"){
                    setVideoData(data.video);
                }else{
                    alert("Unauthorized")
                    navigate('/login')
                }

                // Ensure `userData` is available before checking
                /*if (userData) {
                    const enrolled = checkEnrollment(userData, data.video);
                    if (!enrolled) {
                        console.log("User is not enrolled in the course");
                        alert("User is not enrolled in the course");
                        //navigate('/dashboard'); // Redirect if not enrolled
                    }
                }*/
            } else {
                alert("Video not found");
                //navigate('/dashboard'); 
            }
        } catch (error) {
            console.error('Error checking video data:', error);
            //alert("An error occurred while checking the video");
            //navigate('/dashboard'); // Fallback in case of error
        } 
    }, [id, navigate]);

    useEffect(() => {
        // Fetch video data only when `id` and `userData` are available
        if (id) {
            checkVideo();
        }
    }, [id, checkVideo]);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    /*const fetchUserData = useCallback(async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                navigate('/login', { state: { serverError: 'Please log in to access the dashboard.' } });
                return;
            }
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`, // Include JWT token in Authorization header
                    'Content-Type': 'application/json',
                }
            };

            const response = await axios.get('https://www.opecstat.com/opecstat_server/user/getUser', config);
            if (!response.status || response.status !== 200) {
                navigate('/login', { state: { serverError: 'Please log in to access the dashboard.' } });
                return;
            }

            setUserData(response.data.user); // Set user data received from backend
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.log('Session expired. Please log in again.');
                navigate('/login', { state: { serverError: 'Session expired. Please log in again.' } });
            } else {
                console.error('Error fetching user data:', error);
                alert("Your connection is unstable");
            }
        }
    }, [navigate]);

    useEffect(() => {
        fetchUserData();
    }, [fetchUserData]);

    useEffect(() => {
        if (userData && userData.formFilled === 0) {
            setCurrentView("onboard");
        }
    }, [userData]);*/

    const renderView = (view) => {
        setCurrentView(view);
    };

    const getViewComponent = () => {
        switch (currentView) {
            //case 'onboard':
                //return <OnboardForm userData={userData} onProfileUpdate={fetchUserData} screenWidth={screenWidth}/>;
            case 'home':
                return <WebinarView videoData={videoData}/>;
            //case 'puc':
                //return <PageUnderCreation renderView={renderView} />;
            //case 'create_swap':
                //return <CreateSwapForm userData={userData} onProfileUpdate={fetchUserData} screenWidth={screenWidth}/>;
            //case 'view_offers':
                //return <ViewSwapOffers userData={userData} onProfileUpdate={fetchUserData} screenWidth={screenWidth}/>;
            default:
                return <WebinarView videoData={videoData}/>;;
        }
    };

    return ( 
        <>
            {!videoData && <center><div className='h-screen w-full bg-slate-600 text-dark text-5xl'><img src={loader1} alt='loader' /><br/>Preparing your Webinar Video...</div></center>}
            {
                videoData &&
                <div id="page-top">
                    <div id="wrapper">
                        <div id="content-wrapper" className="d-flex flex-column">
                            <div id="content" className='mt-5'>
                                <div className="container-fluid">
                                    {getViewComponent()}
                                </div>
                            </div>
                            <footer className="sticky-footer bg-white">
                                <div className="container my-auto">
                                    <div className="copyright text-center my-auto">
                                        <span>Copyright &copy; opecstat </span>
                                    </div>
                                </div>
                            </footer>
                        </div>
                    </div>
                    <a className="scroll-to-top rounded" href="#page-top">
                        <i className="fas fa-angle-up"></i>
                    </a>
                    <div className="modal fade" id="logoutModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
                                    <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">Select "Logout" below if you are ready to end your current session.</div>
                                <div className="modal-footer">
                                    <button className="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                                    <a className="btn btn-primary" href="login.html">Logout</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default Webinar;
