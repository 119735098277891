import React, { useEffect, useState } from "react";
import useFetch from "../custom_hooks/useFetch";

const ViewSubCourses = ({ instructorData, renderView, mainCourse }) => {
    const [subCourses, setSubCourses] = useState([]);
    const { result, isResultPending, resultError } = useFetch(
        `https://opecstat.com/opecstat_server/course/getSubCourseByMainCourseId/${mainCourse._id}`
    );

    if (resultError) {
        alert(JSON.stringify(resultError));
    }

    useEffect(() => {
        if (!isResultPending && result) {
            setSubCourses(result.courses);
        }
        console.log(subCourses);
    }, [result, isResultPending]);

    return (
        <div className="row me-1 ms-1">
            <table className="table table-striped table-bordered table-responsive">
                <thead>
                    <tr>
                        <th>S/N</th>
                        <th>Course Name</th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {result && subCourses.length > 0 ? (
                        subCourses.map((course) => (
                            <tr key={course._id}>
                                <td></td>
                                <td>{course.title}</td>
                                <td>
                                    <button className="btn btn-primary btn-sm">Edit</button>
                                </td>
                                <td>
                                    <button className="btn btn-warning btn-sm" onClick={()=>renderView('view_lessons', course)}>View Lessons</button>
                                </td>
                                <td>
                                    <button className="btn btn-danger btn-sm">Delete</button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="4">Loading Courses...</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};



/*const GetVideos = () =>{
    return(
        <div>
        {subCourses.length > 0 ? (
            <ul className="list-group">
                {subCourses.map((course) => (
                    <li key={course._id} className="list-group-item d-flex justify-content-between align-items-center">
                        <span>{course.title}</span>
                        <div>
                            <button className="btn btn-primary btn-sm me-2">Edit</button>
                            <button className="btn btn-warning btn-sm me-2">View Sub Courses</button>
                            <button className="btn btn-danger btn-sm">Delete</button>
                        </div>
                    </li>
                ))}
            </ul>
        ) : (
            <p>No subcourses available</p>
        )}
        </div>
    )
}*/

export default ViewSubCourses;
