import React, { useEffect, useState } from 'react'
import logo from '../../../assets/images/opec10.png'
import sam8 from '../../../assets/images/copy_images/python_copy/sam8.jpg'
import sam12 from '../../../assets/images/copy_images/python_copy/sam12.jpg'
import companies_python from '../../../assets/images/copy_images/python_copy/companies_python.png'
import sam11 from '../../../assets/images/copy_images/python_copy/sam11.jpg'
import test1 from '../../../assets/images/copy_images/python_copy/1.jpg'
import test2 from '../../../assets/images/copy_images/python_copy/2.jpg'
import test3 from '../../../assets/images/copy_images/python_copy/3.jpg'
import test4 from '../../../assets/images/copy_images/python_copy/4.png'
import test5 from '../../../assets/images/copy_images/python_copy/5.png'
import test6 from '../../../assets/images/copy_images/python_copy/6.png'
import test7 from '../../../assets/images/copy_images/python_copy/7.png'
import test8 from '../../../assets/images/copy_images/python_copy/8.jpg'
import test9 from '../../../assets/images/copy_images/python_copy/9.png'
import FlutterwaveSubscription from '../../paymentComponents/flutterwaveSubscription'
import { Link } from 'react-router-dom'

const LearnPythonView = () => {
    const [slotCount, setSlotCount] = useState(99);  // Initialize with a default count (you may change this based on the logic)
    const maxSlots = 130;  // Total number of available slots
    const [timer, setTimer] = useState(null);
    const [randomName, setRandomName] = useState('');
    const [timeDistance, setTimeDistance] = useState(100)
    const names = [
        'David Olayinka',
        'Musa Danjuma',
        'Aisha Bello',
        'Chinonso Eze',
        'Ibrahim Sani',
        'Ngozi Okafor',
        'Adamu Garba',
        'Kemi Adeyemi',
        'Emeka Nwankwo',
        'Fatima Mohammed',
        'Tunde Alabi',
        'Blessing Udo',
        'Samuel Obiniyi',
        'Oluwaseun Adebayo',
        'Halima Abdullahi',
        'Peter Okonkwo',
        'Esther Akinola',
        'Yusuf Umar',
        'Adaobi Chukwu',
        'Funmi Ojo',
        'Chidi Uzoma',
        'Rasheed Lawal',
        'Amaka Nwachukwu',
        'Idris Balogun',
        'Titilayo Ogundipe',
        'Uchechi Nnamani',
        'Zainab Suleiman',
        'Femi Adetola',
        'Abdulrahman Usman',
        'Chizoba Okeke',
        'Bola Shonibare',
        'Maryam Kabir',
        'Kunle Taiwo',
        'Efe Orhue',
        'Adebisi Falade',
        'Ifeanyi Ikenna',
        'Bukola Oyedele',
        'Hauwa Mustapha',
        'Obinna Anyanwu',
        'Tope Aina',
        'Sani Shehu',
        'Patience Igwe',
        'Gbenga Akintola',
        'Chidera Nwosu',
        'Abike Dabiri',
        'Rotimi Olaniyan',
        'Folake Coker',
        'Ahmed Tijani',
        'Nkechi Umeh',
        'Olumide Fasakin'
    ];
      // Sample names for notification
   
    useEffect(() => {
      // Handle countdown timer
      const dt = new Date();
      dt.setMinutes(dt.getMinutes() + 15);
      const countDownDate = dt.getTime();
  
      const interval = setInterval(() => {
        const now = new Date().getTime();
        const distance = countDownDate - now;
        setTimeDistance(distance)
        if (distance < 0) {
          clearInterval(interval);
          setTimer("EXPIRED");
        } else {
          const days = Math.floor(distance / (1000 * 60 * 60 * 24));
          const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((distance % (1000 * 60)) / 1000);
          setTimer(`${days}:${hours}:${minutes}:${seconds}`);
        }
      }, 1000);
  
      // Handle slot count with cookies
      if (!getCookie('slot')) {
        setSlotCount(99);  // Default value
      } else {
        const savedSlots = parseInt(getCookie('slot'), 10);
        if (savedSlots >= maxSlots) {
          //setSlotCount(99);
          setSlotCount(maxSlots);
        } else {
            //setSlotCount(99);
            setSlotCount(savedSlots);
        }
      }
  
      // Slot count update
      const slotInterval = setInterval(() => {
        if (slotCount < maxSlots) {
          const newSlotCount = slotCount + 1;
          setSlotCount(newSlotCount);
          setCookie('slot', newSlotCount, 1);
          if (newSlotCount >= maxSlots) {
            clearInterval(slotInterval);
          }
        }
      }, 8000);
  
      return () => {
        clearInterval(interval);
        clearInterval(slotInterval);
      };
    }, [slotCount]);
  
    const setCookie = (name, value, days) => {
      const d = new Date();
      d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
      document.cookie = `${name}=${value}; expires=${d.toUTCString()}; path=/`;
    };
  
    const getCookie = (name) => {
      const nameEq = name + "=";
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1);
        if (c.indexOf(nameEq) === 0) return c.substring(nameEq.length, c.length);
      }
      return "";
    };
  
    useEffect(() => {
      if (slotCount < maxSlots) {
        // Randomly pick a name for the notification
        const randomIndex = Math.floor(Math.random() * names.length);
        setRandomName(names[randomIndex]);
      }
    }, [slotCount]);

    const red_bold = {
        color:"red"
    }
    const marketImg = {
        width:'90%',
        height:"80%",
        borderRadius:"20px"
    }

    const sticky = {
        position: "-webkit-sticky", /* Safari */
        position: "sticky",
        top: 0,
        zIndex:"1800", 
        width:"100%", 
        boxShadow:"0px 1px 5px 1px silver", 
        backgroundColor:"skyblue"
    }

    const handleSuccess = (message) => {
        alert(message);
      };
    
      const handleFailure = (message) => {
        alert(message);
      };
    return ( 
        <div className="row" style={{color:"black"}}>
            <div className='col-md-12' style={{paddingLeft:"3%", backgroundColor:"skyblue"}}>
                <div className='col-md-7'>
                    <br/><p className='fw-bold'>VIDEO TUTORIAL:</p>
                    <br/><br/><br/><br/>
                    <h2 className='fs-2' style={{fontFamily:"monstreal-bold"}}><b><big><big>Do you want to learn Python Programming?</big></big></b></h2><br/><br/>
                    <p className='fs-4'>
                        Join Over 1,289 Students. Become a <b>Certified Python Developer</b> from the comfort of your home <b>in 3 Months</b> even if you had no prior programming knowledge<b style={red_bold}> - Guaranteed.</b>
                    </p><br/><br/>
                </div>
                <div className='col-md-5'>
                    
                </div>
            </div>
            {/*<!---Small Screen-->*/}
            <div className='col-md-12 d-sm-block d-xs-block d-lg-none d-md-none d-xl-none' style={sticky}>
                <div className='col-md-12 visible-lg visible-md' style={{borderStyle:"solid", borderRadius:"10px", width:"100%", height:"100%", borderWidth:"4px", padding:"10px", height:"130px"}}>
                    <div style={{fontSize:"12px"}}>
                        <h6>{/*<b style={red_bold}>Slots Taken</b>&nbsp;*/}<span className='secure-slot-btn-small pull-right'><Link to="/register/vi" className='btn btn-lg btn-danger'>Enroll for Free</Link>{/*<FlutterwaveSubscription prefilledEmail='' prefilledName='' userId='' amount={67000} onSuccess={handleSuccess} onFailure={handleFailure} buttonDisplayText="Secure your spot"/>*/}</span></h6>
                        <center className=' slot-count-down'>
                            <h2><s>N392,400</s> <br/></h2>
                            <h3><b><span className='demo-timer'>{timer}</span></b></h3>
                            <div className='alert-div alert alert-success' style={{fontWeight:"bold"}}>
                                {timeDistance > 0 && (
                                    <div>
                                    <p><b>{randomName} just secured a spot!</b></p>
                                    </div>
                                )}
                            </div><br/>
                        </center>
                    </div>
                </div><br/>
            </div><br/><br/>
            <div className='row' style={{width:"100%", backgroundColor:"#fff", marginBottom:"3%"}}>
                <div className='row' style={{marginLeft:"5%", marginRight:"5%", width:"100%"}}>
                    <div className='col-md-7'><br/><br/>
                        <h3><b style={red_bold}>Yes. I mean it!! </b></h3><br/>
                        
                        <p style={{fontSize:"18px"}}>
                            <b>Hi, I'm Sam and I want to teach you how to code with Python in 11 hours.</b><br/> 

                            There will be very little theory, mainly practical and <b style={red_bold}><big>no pushing back and forth because, hey! That's what takes time in all 
                            these long trainings anyway</big></b> and believe it or not, in the real world, they are looking for those who have the practical understanding
                            of Python Programming.  <br/><br/>If you actually want to learn how to code in
                            Python practically, <b style={red_bold}>and also how to earn from what you are about to learn,</b> then come along and I'm going to show you.<br/><br/><br/><br/>
                        </p>
                            
                            <h2><b style={red_bold}>Who the heck am I and why should you listen to me? </b></h2><br/>
                            
                        <p style={{fontSize:"18px"}}>
                            You may be wondering. What gives me the authority to make all these claims?
                            <b style={red_bold}>How are you sure I can deliver on what I'm saying?</b> How do you know I will change your life for the better?<br/><br/>
                            <span className='col-md-12'>
                                <img src={sam8} style={marketImg}/>
                            </span><br/><br/>
                            <b><big>Well you see that's me, but I've been where you are before.</big></b><br/><br/>
                            A few years ago in 2015 after I had graduated, I began to ask myself what next?<br/>
                            <b style={red_bold}>Like most Nigerian graduates, I was actually very broke and didn't have any job in view.</b>
                            So I began to wonder, What next? How can I live a life that is different, because in Nigeria today there are no jobs and the price of everything keeps increasing.<br/><br/><br/>
                            
                            You know how hard it is getting employment in our country and <b style={red_bold}><big>even those who are employed in Nigeria today barely make enough. Before you know it, by the middle of the month your salary is gone.</big></b> <br/><br/>
                            
                            
                            So after looking at all these, I decided to go into programming because it is the quickest way to make money in Nigeria especially if you don't have much capital.<br/> But after attempting to learn some Programming languages, <b style={red_bold}>I realized that there are too many programming languages out there, with all of them promising to be the future</b> or the next big thing but unfortunately, learning some of these so called "next-big-thing" programming languages cost me a ton of money because you see, <b style={red_bold}>a lot of institutions charge as high as $347 that is about &#8358;125,920 to teach programming</b> and it also wasted so much of my time because they'll make the duration of the course so long just to make you think it's worth all that money <b style={red_bold}>but in the end all they'll teach is theory (and that's why most graduates of computer science in Nigeria cannot write practical code).<br/><br/><br/>
                            
                            I was tired of making the same mistake so I had to ask myself three(3) very important questions</b> before selecting the best programming language to learn.<br/><br/><br/>
                            I had to ask:<br/>
                            <h5><b><span className='fa fa-fw fa-check' style={{color:"green", fontSize:"25px"}}></span> Will the programming language I'm about to learn actually be useful?</b></h5><br/>
                            <h5><b><span className='fa fa-fw fa-check' style={{color:"green", fontSize:"25px"}}></span> Will the programming language I'm about to learn have the ability earn me money. Is it profitable?</b></h5><br/>
                            <h5><b><span className='fa fa-fw fa-check' style={{color:"green", fontSize:"25px"}}></span>  And finally I had to ask myself, will it be easy to learn? <p style={{fontSize:"16px", marginLeft:"3%", marginTop:"1%"}}>So that it doesn't take all my time and end up adding to the number of years I've already spent in school like all these long courses that teach only theory.</p></b></h5><br/><br/>
                            
                            <span style={{fontSize:"18px"}}>After trying to learn a few languages, <b style={red_bold}>I finally came across the Almighty Python and my story changed.</b></span><br/><br/>
                            <span className='col-md-12'>
                                    <img src={sam12} style={marketImg}/>
                            </span><br/><br/><br/><br/>
                        </p>
                            
                            <h2><b style={red_bold}>So what makes Python so different?? </b></h2><br/>
                            
                        <p style={{fontSize:"18px"}}>   
                            One of the most interesting things about python (and that's probably why IT IS SO POPULAR AND DESIRED BY TOP ORGANIZATIONS) is the fact that <b style={red_bold}>Python is a general purpose language which means <big>it can be used for almost everything</big></b> which makes it very useful and anyone who knows it well (By "well" I mean those who know it practically not just theory), is a hot cake in the industry. why? well, its because:<br/><br/>
                            <ol style={{fontSize:"21px"}}>
                                <li style={{listStyle:"none"}}><span className='fa fa-fw fa-check' style={{color:"black", fontSize:"25px"}}></span> Python can be used for Web Design and Development</li>
                                <li style={{listStyle:"none"}}><span className='fa fa-fw fa-check' style={{color:"black", fontSize:"25px"}}></span> Python is used in Robotics</li>
                                <li style={{listStyle:"none"}}><span className='fa fa-fw fa-check' style={{color:"black", fontSize:"25px"}}></span> Python is used in GUI and Game development</li>
                                <li style={{listStyle:"none"}}><span className='fa fa-fw fa-check' style={{color:"black", fontSize:"25px"}}></span> Python is used in Data Science</li>
                                <li style={{listStyle:"none"}}><span className='fa fa-fw fa-check' style={{color:"black", fontSize:"25px"}}></span> Python is used in Artificial Intelligence</li>
                            </ol><br/><br/><br/>
                            <span style={{fontSize:"18px"}}>Python is even used by NASA and <b style={red_bold}><big>Facebook, the social media giant has moved over from PHP as their core language to Python-[Now that says a lot!]</big></b></span><br/><br/><br/>
                            
                            <span className='col-md-12'>
                                <img src={companies_python} style={marketImg}/>
                            </span>
                            
                            <span style={{fontSize:"20px"}}>
                                <br/><br/>The knowledge of Python along with the skills I'm going to teach you in this video course has changed my life forever.<br/><br/><br/>

                                I never have to worry again about money. I've passed that stage. I never have to worry about my wife needing something, or providing my children with what they need, or my siblings.<br/><br/><br/>
                                
                                That is the power of a learning a skill, and not just any skill, but <b style={red_bold}>a skill with high earning potential</b> like Python Programming and because I earn online, I also don't have to worry about the Naira collapsing.<br/> This is truly The Good Life <b style={red_bold}><big>-[ and you too can own this once you learn the secrets I'm about reveal to you]</big></b><br/><br/>
                                
                                <span className='col-md-12'>
                                    <img src={sam11} style={marketImg}/>
                                </span>
                            </span><br/><br/><br/><br/>
                        </p>   
                        
                        <h2><b style={red_bold}>Why I Highly Recommend Python Programming </b></h2><br/>
                        <p style={{fontSize:"18px"}}>
                                <h5><b>1. Python is the programming language with the second highest earning potential in the world- </b></h5><p style={{fontSize:"18px", marginLeft:"3%"}}>This is by far the main reason I've made so much money with Python because <b style={red_bold}>people will pay you a lot</b> for Python projects</p><br/><br/>
                                <h5><b>2. Python commands demand - </b></h5><p style={{fontSize:"18px", marginLeft:"3%"}}>Like we have established before. Python is used in many industries and because of this <b style={red_bold}>you will never lack job opportunities.</b> I've worked for different companies across different fields because of my knowledge of python and <b style={red_bold}>you can too!</b></p><br/><br/>
                                <h5><b>3. Python is easy to learn - </b></h5><p style={{fontSize:"18px", marginLeft:"3%"}}>Python is easy to learn so <b style={red_bold}>you don't have to wait too long before you start earning from it.</b></p><br/><br/>
                                <h5><b>4. Python gives you security - </b></h5><p style={{fontSize:"18px", marginLeft:"3%"}}>This is one of the reasons why it has such a high earning potential because everybody wants security and Python is a very secure language. So you are rest assured that <b style={red_bold}>people will keep giving you jobs because we all want security</b></p><br/><br/>
                                
                        </p><br/>
                            
                            
                        <h2><b style={red_bold}>Here are what those who have learnt are saying</b></h2><br/> 
                            <div className='col-md-12'>
                                <section className="testimonials">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div id="customers-testimonials" className="owl-carousel">
                                                    {/*<!--TESTIMONIAL 1 -->*/}
                                                    <div className="item">
                                                        <div className="shadow-effect">
                                                            <img className="img-circle" src={test3}/>
                                                            <p></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div id="customers-testimonials" className="owl-carousel">
                                                    {/*<!--TESTIMONIAL 1 -->*/}
                                                    <div className="item">
                                                        <div className="shadow-effect">
                                                            <img className="img-circle" src={test4}/>
                                                            <p></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div id="customers-testimonials" className="owl-carousel">
                                                    {/*<!--TESTIMONIAL 1 -->*/}
                                                    <div className="item">
                                                        <div className="shadow-effect">
                                                            <img className="img-circle" src={test5}/>
                                                            <p></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div id="customers-testimonials" className="owl-carousel">
                                                    {/*<!--TESTIMONIAL 1 -->*/}
                                                    <div className="item">
                                                        <div className="shadow-effect">
                                                            <img className="img-circle" src={test2}/>
                                                            <p></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div id="customers-testimonials" className="owl-carousel">
                                                    {/*<!--TESTIMONIAL 1 -->*/}
                                                    <div className="item">
                                                        <div className="shadow-effect">
                                                            <img className="img-circle" src={test1}/>
                                                            <p></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div id="customers-testimonials" className="owl-carousel">
                                                    {/*<!--TESTIMONIAL 1 -->*/}
                                                    <div className="item">
                                                        <div className="shadow-effect">
                                                            <img className="img-circle" src={test6}/>
                                                            <p></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div id="customers-testimonials" className="owl-carousel">
                                                    {/*<!--TESTIMONIAL 1 -->*/}
                                                    <div className="item">
                                                        <div className="shadow-effect">
                                                            <img className="img-circle" src={test7}/>
                                                            <p></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div id="customers-testimonials" className="owl-carousel">
                                                    {/*<!--TESTIMONIAL 1 -->*/}
                                                    <div className="item">
                                                        <div className="shadow-effect">
                                                            <img className="img-circle" src={test8}/>
                                                            <p></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div id="customers-testimonials" className="owl-carousel">
                                                    {/*<!--TESTIMONIAL 1 -->*/}
                                                    <div className="item">
                                                        <div className="shadow-effect">
                                                            <img className="img-circle" src={test9}/>
                                                            <p></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div><br/><br/>
                            
                        
                        <h2><b style={red_bold}>In this video course, I will reveal to you:</b></h2><br/>
                        <p style={{fontSize:"18px"}}>
                            <h5><b><span className='fa fa-fw fa-check-circle' style={{color:"green", fontSize:"25px"}}></span> How to code with Python - [Practical and straight forward] </b></h5><br/>
                            <h5><b><span className='fa fa-fw fa-check-circle' style={{color:"green", fontSize:"25px"}}></span> The 6 deadly mistakes I made while learning Python and how to avoid them - [Number 4 almost cost me my job] </b></h5><br/>
                            <h5><b><span className='fa fa-fw fa-check-circle' style={{color:"green", fontSize:"25px"}}></span> The little-known secret tricks and best practices when using python </b></h5><br/>
                            <h5><b><span className='fa fa-fw fa-check-circle' style={{color:"green", fontSize:"25px"}}></span> The 3 steps to making your code highly dynamic and automated. - [You can never make money while you sleep without first knowing this] </b></h5><br/>
                            <h5><b><span className='fa fa-fw fa-check-circle' style={{color:"green", fontSize:"25px"}}></span> SHOCKING!! I'm also going to show you the 5 important steps I took that moved me from earning &#8358;30,000 a month to earning &#8358;400,000 consistently. [I have never revealed this anywhere else] </b></h5><br/><br/>
                            
                        </p><br/>
                        
                            <h2><b style={red_bold}>I won't leave you Hanging. I would give you these for free:</b></h2><br/>
                        <p style={{fontSize:"18px"}}>
                            <h5><b><span className='fa fa-fw fa-check-circle' style={{color:"green", fontSize:"25px"}}></span> Four(4) Highly in-demand Python programming text-books worth &#8358;16,000</b></h5><br/>
                            <h5><b><span className='fa fa-fw fa-check-circle' style={{color:"green", fontSize:"25px"}}></span> Lifetime access to the videos and every resource you would use to learn</b></h5><br/>
                            <h5><b><span className='fa fa-fw fa-check-circle' style={{color:"green", fontSize:"25px"}}></span> Free Mentorship for the duration of your learning </b></h5><br/>
                            <h5><b><span className='fa fa-fw fa-check-circle' style={{color:"green", fontSize:"25px"}}></span> Free access to another of my video course currently worth &#8358;92,000 where I teach you the 3 hidden secrets to selling anything to anyone -</b> <span style={red_bold}>[Because to succeed in life, you must know how to sell yourself and your skill and I will show you how to do that]</span><p style={{fontSize:"18px", marginLeft:"3%, margin-top:1%"}}>Top companies use these secrets and they don't want you to know about it but these secrets have changed my life like "night and day" and I will reveal them to you. You must know these secrets if you're ever going to make money from what you're about to learn</p></h5>
                            <p><br/><br/><br/>

                                <span style={{fontSize:"20px"}}>
                                    <b>IMPORTANT!!:</b>  Even with all these resources that you would been given, you cannot make it in life if you are not willing to put in the effort and hard work. All these will only work for you and benefit you if you are ready and willing to invest in yourself and push yourself because at the end of the day you are the one who will decide to move forward or stay where you are.
                                </span>
                            </p>
                        </p><br/><br/><br/><br/>
                            <h2><b style={red_bold}>So here is the value of everything that you would be getting:</b></h2><br/><br/>
                            <table className='table table-responsive table-bordered table-striped' style={{fontSize:"20px", color:"black"}}>
                                <tr>
                                    <th>
                                        S/N 
                                    </th>
                                    <th>
                                        Value 
                                    </th>
                                    <th>
                                        Worth
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        1. 
                                    </td>
                                    <td>
                                        How to code with Python - [Practical and straight forward] 
                                    </td>
                                    <td style={{fontWeight:"bold"}}>
                                        &#8358;44,000
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        2. 
                                    </td>
                                    <td>
                                        The 6 deadly mistakes I made while learning Python and how to avoid them - [Number 4 almost stopped me from being employed] 
                                    </td>
                                    <td style={{fontWeight:"bold"}}>
                                        Priceless!
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        3. 
                                    </td>
                                    <td>
                                        The little-known secret tricks and best practices when using python 
                                    </td>
                                    <td style={{fontWeight:"bold"}}>
                                        &#8358;7,400
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        4. 
                                    </td>
                                    <td>
                                        The 3 steps to making your code highly dynamic and automated. - [You can almost never earn while you sleep without learning this one] 
                                    </td>
                                    <td style={{fontWeight:"bold"}}>
                                        &#8358;17,000
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        5. 
                                    </td>
                                    <td>
                                        SHOCKING!! The 5 important steps I took that moved me from earning 30,000 Naira a month to earning 400,000 Naira consistently. [I have never revealed this anywhere else] 
                                    </td>
                                    <td style={{fontWeight:"bold"}}>
                                        &#8358;81,000
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        6. 
                                    </td>
                                    <td>
                                        Four(4) Highly in-demand Python programming text-books 
                                    </td>
                                    <td style={{fontWeight:"bold"}}>
                                        &#8358;16,000
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        7. 
                                    </td>
                                    <td>
                                        Lifetime access to every resource you would use to learn 
                                    </td>
                                    <td style={{fontWeight:"bold"}}>
                                        Priceless!
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        8. 
                                    </td>
                                    <td>
                                        Mentorship for the duration of your learning 
                                    </td>
                                    <td style={{fontWeight:"bold"}}>
                                        &#8358;135,000
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        9. 
                                    </td>
                                    <td>
                                        Access to my video course where I teach you the 3 hidden secrets to selling anything to anyone - <small><b>[Top companies use these secrets and they don't want you to know about it but these secrets have changed my life like "night and day" and I will reveal them to you. You must know these secrets if you're ever going to make money from what you're about to learn]</b></small>
                                    </td>
                                    <td style={{fontWeight:"bold"}}>
                                        &#8358;92,000
                                    </td>
                                </tr>
                                <tr style={{fontSize:"23px"}}>
                                    <td>
                                        
                                    </td>
                                    <td>
                                        Total: 
                                    </td>
                                    <td style={{fontWeight:"bold"}}>
                                        &#8358;392,400
                                    </td>
                                </tr>
                                
                            </table>
                            
                            <br/><br/>
                            
                            <p style={{fontSize:"20px"}}>
                                <span>
                                    <b style={red_bold}>Now, do you see why a lot of institutions charge so high to teach programming?</b><br/> It's not their fault and I don't blame them but because I want to add value and give as many people as possible the opportunity to experience the financial freedom I've experienced as a result of learning Python, 
                                </span>
                            </p>
                            
                            <p style={{fontSize:"23px", fontWeight:"bold"}}>
                                <span style={red_bold}>
                                    So for a limited time only I would be giving you, yes you! access to learn all this for {/*just*/} <del style={{color:"black"}}>&#8358;392,400</del> <b style={{color:"green", fontSize:"26px"}}>FREE{/*&#8358;67000*/}</b> 
                                </span><br/><br/>
                            
                            </p>
                            
                            <p style={{fontSize:"18px", textAlign:"center"}}>
                            <hr/>
                                <center>
                                    <h3>Enroll For this course and Secure your spot for FREE</h3><br/> 
                                    <h2><s>N392,400</s> <br/><span className='text-danger fw-bold'>ENROLL FOR FREE</span></h2>
                                    {/*<span style={{fontSize:"18px"}}><b style={red_bold}>&#8358;67,000</b></span> | <del style={{color:"gray", fontSize:"18px"}}>Retail Price: &#8358;392,400</del> | <span style={{color:"red", fontSize:"18px"}}>Save 100%</span><br/><br/>*/}
                                    <p className='secure-slot-btn'>
                                        <Link to="/register/vi" className='btn btn-lg btn-danger'>Enroll for Free</Link>
                                        {/*<FlutterwaveSubscription prefilledEmail='' prefilledName='' userId='' amount={67000} onSuccess={handleSuccess} onFailure={handleFailure} buttonDisplayText="Enroll for Free"/>*/}
                                    <br/></p>
                                    {/*<!-- Display the countdown timer in an element -->*/}
                                    <p style={{fontSize:"20px"}} className='text-center'>
                                    <center>
                                        <h3><b><span className='demo-timer'>{timer}</span></b></h3>
                                        <div className='alert-div alert alert-success' style={{fontWeight:"bold"}}>
                                            {timeDistance > 0 && (
                                                <div>
                                                <p><b>{randomName} just secured a spot!</b></p>
                                                </div>
                                            )}
                                        </div>
                                    </center>
                                </p>
                                </center>
                            <hr/>
                        </p><br/><br/>
                        
                        <h3><b style={red_bold}>Attention:</b></h3><br/>
                        <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                        <span>
                            <b style={red_bold}>I truly value each one of you</b>, and that's why I'm offering you access to incredible resources valued at 
                            <span style={{ color: "black" }}> &#8358;392,400</span> absolutely <b style={{ color: "green" }}>FREE</b>. But let me be honest—it wasn’t easy. It took me years of dedication and substantial investment to gain the knowledge and skills I’m about to share with you.<br/><br/>

                            To ensure only serious and committed individuals benefit from this life-changing opportunity, there’s one important condition:<br/>
                            If you miss your classes for 7 consecutive days, you will be dis-enrolled from the program. To rejoin, a nominal commitment fee of <b style={{ color: "black" }}>&#8358;10,000</b> will be required.<br/><br/>

                            This is not just a program; it’s a chance to transform your life. Let’s make sure you’re ready to take full advantage of it!
                        </span>
                        </p>

                        
                        {/*<div className='row'>
                            <div className='col-md-12' style={{borderStyle:"solid", borderRadius:"10px", width:"100%", height:"100%", borderWidth:"2px", padding:"20px", marginBottom:"6%"}}>
                                <h4><b style={red_bold}>Is there a Guarantee?</b></h4><br/>
                                <p style={{fontSize:"18px"}}>
                                    <b>Yes! All my teachings have a 3 day, no-questions-asked guarantee.</b><br/><br/>
                                    <h4>But that's is not all.</h4>

                                    <b><big>If you do not think you're not getting enough value from the course after 3 days. Let me know and I will fully refund your money back along with a written apology -</big></b>
                                    <p style={{fontSize:"18px"}}>You see, I've been doing this for 4 years now. I've coached and impacted over 10,000 people in 7 different countries. I don't tell you this to brag, I say this because frankly, my reputation is on the line.<br/>If I don't deliver the results I promised, my reputation would go down the trash.</p><br/><br/>
                                </p>
                            </div>
                        </div><br/><br/>
                        
                        <h2><b style={red_bold}>Fast Action Takers</b></h2><br/>
                        <p style={{fontSize:"18px", fontWeight:"bold"}}>
                            <span>
                                <b style={red_bold}>If you have made it this far it means that you are serious and ready to take advantage of this course and I've made this fast action bonus just for you.</b><br/> I would be you giving access to all this value worth <span style={{color:"black"}}>&#8358;392,400</span> for just <b style={{color:"green"}}>&#8358;24,499.</b>
                                <br/><br/><span style={red_bold}>Don't celebrate just yet!</span> 
                            </span>
                            <span>
                                <br/><br/><b style={red_bold}>I love you all but</b> giving access to all this value worth <span style={{color:"black"}}>&#8358;392,400</span> for just <b style={{color:"green"}}>&#8358;24,499</b> is not easy because it took years and cost me a lot to learn what I 
                                will be teaching you.<br/><br/> Therefore I would be limiting the number of slots to just <span style={red_bold}><big>130 people </big></span>so that only those who are serious and ready to change their life for better would take advantage of this offer
                            </span>
                        </p>
                        <div className='col-md-12' style={{borderStyle:"solid", borderRadius:"10px", width:"100%", height:"100%", borderWidth:"4px", padding:"20px", height:"130px"}}>
                            <h4><b style={red_bold}>Slots Taken</b></h4>
                            <p style={{fontSize:"18px"}} className='text-center'>
                                <center className=' slot-count-down'>
                                    <h3><b><span className='slot'>{slotCount}</span>/<span className='max'>{maxSlots}</span></b></h3>
                                    <div className='alert-div alert alert-success' style={{fontWeight:"bold"}}>
                                            {timeDistance > 0 && (
                                                <div>
                                                <p><b>{randomName} just secured a spot!</b></p>
                                                </div>
                                            )}
                                    </div><br/>
                                    <p className='secure-slot-btn'><FlutterwaveSubscription prefilledEmail='' prefilledName='' userId='' amount={24499} onSuccess={handleSuccess} onFailure={handleFailure} buttonDisplayText="Secure Your Spot"/><br/></p>
                                </center>
                            </p><br/><br/><br/>
                        </div>*/}
                        
                    </div>
                    <div className='col-md-4 d-lg-block d-xl-block d-md-block d-none' style={{position:"fixed", top:"20%", right:"3%", width:"90%"}}>
                        <br/><br/><br/>
                            <div className='col-md-12 visible-lg visible-md' style={{borderStyle:"solid", borderRadius:"10px", width:"100%", height:"100%", borderWidth:"4px", padding:"20px", height:"150px"}}>
                               
                                <p style={{fontSize:"18px"}} className='text-center'>
                                    <center className=' slot-count-down'>
                                        <h2><s>N392,400</s> <br/><span className='text-danger fw-bold'>ENROLL FOR FREE</span></h2>
                                        <h3><b><span className='demo-timer'>{timer}</span></b></h3>
                                        <div className='alert-div alert alert-success' style={{fontWeight:"bold"}}>
                                            {timeDistance > 0 && (
                                                <div>
                                                <p><b>{randomName} just secured a spot!</b></p>
                                                </div>
                                            )}
                                        </div><br/>
                                        <p className='secure-slot-btn'>
                                            <Link to="/register/vi" className='btn btn-lg btn-danger'>Enroll for Free</Link>
                                            {/*<FlutterwaveSubscription prefilledEmail='' prefilledName='' userId='' amount={67000} onSuccess={handleSuccess} onFailure={handleFailure} buttonDisplayText="Enroll for Free"/>*/}
                                        <br/></p>
                                    </center>
                                </p>
                            </div>
                        
                    </div>
                </div>
            </div>
        </div>
     )
}
 
export default LearnPythonView