//import Navbar from "../components/Navbar";
import '../assets/styles/style.css';
import '../assets/styles/responsive.css';
import '../assets/styles/font-awesome.min.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import LoginForm from "../components/auth/LoginForm"
import LearnPythonView from '../components/marketing/learn-python/LearnPythonView';


const LearnPython = () => {
    return (
      <LearnPythonView/>
    );
  };
  
  export default LearnPython;