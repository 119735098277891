import React,{ useEffect, useState } from "react";


            
const useCheckInternet = () => {
    const [isOnline, setIsOnline] = useState(true);
  
    useEffect(() => {
      const checkConnection = async () => {
        try {
          const response = await fetch('https://www.google.com', {
            method: 'HEAD',
            mode: 'no-cors',
          });
          if (response) {
            setIsOnline(true);
          }
        } catch (error) {
          setIsOnline(false);
        }
      };
  
      // Perform the check on mount and every 5 seconds
      checkConnection();
      const intervalId = setInterval(checkConnection, 5000);
  
      return () => clearInterval(intervalId);
    }, []);
  
    return isOnline;
};

export default useCheckInternet;