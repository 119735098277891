import React from "react";
import image1 from '../../assets/images/AdobeStock_809077537.jpeg';

const AuthSidebar = () => {
    return ( 
        <div className="d-none d-md-block d-lg-block col-md-6">
            <img src={image1} className="h-100 h-screen w-full" alt="side_image"/>
        </div>
     );
}
 
export default AuthSidebar;