import { useState, useEffect } from 'react';
import { Button, Form, ListGroup } from 'react-bootstrap';
import useFetch from '../../custom_hooks/useFetch';

const QuizComponent = ({ videoData, userData, handleTestTaken }) => {
  const [quizId, setQuizId] = useState(null);
  const [quizData, setQuizData] = useState([]);
  const [quizAnswers, setQuizAnswers] = useState({});
  const [quizResult, setQuizResult] = useState(null);
  const [quizTime, setQuizTime] = useState(0); // Time allocated for the quiz
  const [timeLeft, setTimeLeft] = useState(0); // Timer countdown
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isQuizSaved, setIsQuizSaved] = useState(false);
  const [score, setScore] = useState(0);
  const [hasTakenQuiz, setHasTakenQuiz] = useState(true);
  const [previousScore, setPreviousScore] = useState(null);

  const { result, isResultPending, resultError } = useFetch(
    `https://opecstat.com/opecstat_server/quiz/getQuizByVideoId/${videoData._id}`
  );

  useEffect(() => {
    if (!isResultPending && result) {
      setQuizId(result._id);
      setQuizData(result.questions);
      if (result.questions) {
        setQuizTime(result.questions.length * 2); // Assume 2 minutes per question
        setTimeLeft(result.questions.length * 2);
        console.log(JSON.stringify(result.questions, null, 2));
      }

      // Check if the user has previously taken this quiz
      (async () => {
        try {
          const response = await fetch(
            `https://opecstat.com/opecstat_server/quiz/getQuizByUserAndId/${userData._id}/${result._id}`
          );
          const data = await response.json();
          if (response.ok && data.quizFoundStatus) {
            setHasTakenQuiz(true);
            handleTestTaken(true)
            setPreviousScore(data.quizData.score);
            console.log("Taken Data ",JSON.stringify(data,null,2))
          } else {
            setHasTakenQuiz(false);
          }
        } catch (error) {
          console.error('Error fetching previous quiz data:', error);
        }
      })();
    }
  }, [result, isResultPending]);

  useEffect(() => {
    if (timeLeft > 0 && !isSubmitting) {
      const timer = setInterval(() => setTimeLeft((prev) => prev - 1), 60000);
      return () => clearInterval(timer);
    }
  }, [timeLeft, isSubmitting]);

  const handleAnswerChange = (questionIndex, answerIndex) => {
    setQuizAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionIndex]: answerIndex, // Store the index of the selected answer
    }));
  };

  const handleQuizSubmit = async () => {
    const correctAnswersCount = quizData.reduce((total, question, index) => {
      const selectedAnswer = quizAnswers[index]; // Retrieve the selected index
      const correctAnswer = question.correctAnswer; // Correct index from question

      console.log('Selected Answer:', selectedAnswer);
      console.log('Correct Answer:', correctAnswer);

      // Increment score if selected answer matches the correct answer
      return total + (selectedAnswer === correctAnswer ? 1 : 0);
    }, 0);

    // Calculate score as percentage
    const calculatedScore = Math.round((correctAnswersCount / quizData.length) * 100);

    const result = quizData.map((question, index) => {
      const selectedAnswer = quizAnswers[index];
      return {
        question: question.question,
        selectedAnswer,
        correct: selectedAnswer === question.correctAnswer,
      };
    });

    setQuizResult(result);
    setIsSubmitting(true);

    try {
      const response = await fetch(`https://opecstat.com/opecstat_server/quiz/saveQuizResult`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: userData._id,
          videoId: videoData._id,
          quizId: quizId,
          score: calculatedScore, // Save the percentage score
        }),
      });

      const data = await response.json();
      if (response.ok) {
        setScore(calculatedScore); // Display percentage score
        setIsQuizSaved(true);
        setHasTakenQuiz(true); // Mark the quiz as taken
        handleTestTaken(true)
      } else {
        alert(data.message || 'Could not save quiz result.');
      }
    } catch (error) {
      console.error('Error saving quiz result:', error);
      alert('An error occurred while saving the quiz result.');
    }
  };

  return (
    <div style={{ padding: '5px' }}>
      {isQuizSaved && score>=previousScore && (
        <center>
          <div className="alert alert-info">Quiz Saved Successfully</div>
        </center>
      )}
      {hasTakenQuiz && (
              <div className="alert alert-warning">
                You have already taken this quiz before. Your score was <b>{`${isQuizSaved ? score : previousScore}`}%</b>
              </div>
            )}
            {hasTakenQuiz && (
              <div className="alert alert-info">
                <small>
                  You can take the quiz again, but any score below {previousScore} would not be saved.
                </small>
              </div>
            )}
      <div style={{ height: '300px', overflow: 'auto', padding: '5px' }}>
      {quizData && quizData.length ? (
        <Form>
          <>
            
            {quizData.map((question, index) => (
              <div key={index} className="mb-1" style={{ color: 'black' }}>
                <h5>{question.question}</h5>
                {question.options.map((option, i) => (
                  <Form.Check
                    key={i}
                    type="radio"
                    name={`question-${index}`} // Ensures unique group for each question
                    label={option}
                    onChange={() => handleAnswerChange(index, i)} // Pass question index and selected option index
                    checked={quizAnswers[index] === i} // Mark as checked if selected
                  />
                ))}
                <hr />
              </div>
            ))}
            {!isQuizSaved && (
              <div>
                <p className="text-danger">Time Left: {timeLeft} minutes</p>
                <Button onClick={handleQuizSubmit} disabled={isSubmitting}>
                  {isSubmitting ? 'Submitting...' : 'Submit Quiz'}
                </Button>
              </div>
            )}
          </>
        </Form>
      ) : (
        <div>Loading Quiz Data...</div>
      )}

        {!quizData && <div>No Quiz Available</div>}
        {quizResult && (
        <div style={{color:"black"}}>
          <h5>Your Score: {quizData && isQuizSaved && `${score}%`}</h5>
          <ListGroup>
            {quizResult.map((result, index) => (
                <ListGroup.Item className='' key={index}>
                    {result.question} :<br/>  
                    <b className={`${result.correct ? 'text-success' : 'text-danger'}`}>
                        {result.correct ? 'Correct' : 'Incorrect'}
                    </b>
                    {!result.correct && quizData[index]?.options[quizData[index].correctAnswer]
                        ? ` (Correct Answer: ${quizData[index].options[quizData[index].correctAnswer]})`
                        : ''}
                </ListGroup.Item>
            ))}
        </ListGroup>

        </div>
      )}
      </div>
    </div>
  );
};

export default QuizComponent;
