import React, { useEffect, useState } from "react";
import useFetch from "../custom_hooks/useFetch";
import axios from "axios";
import Modal from "react-modal"; // Ensure you install react-modal with `npm install react-modal`

Modal.setAppElement("#root"); // Adjust according to your app structure

// Quiz Modal Component
const QuizModal = ({ showModal, setShowModal, videoId, quizQuestions, setQuizQuestions }) => {
    const [currentQuestion, setCurrentQuestion] = useState({
        question: "",
        options: [],
        correctAnswer: "",
    });

    const handleAddOption = () => {
        setCurrentQuestion((prev) => ({
            ...prev,
            options: [...prev.options, ""],
        }));
    };

    const handleRemoveOption = (index) => {
        setCurrentQuestion((prev) => {
            const updatedOptions = prev.options.filter((_, i) => i !== index);
            return {
                ...prev,
                options: updatedOptions,
                correctAnswer: prev.correctAnswer === String(index) ? "" : prev.correctAnswer,
            };
        });
    };

    const handleOptionChange = (index, value) => {
        setCurrentQuestion((prev) => {
            const updatedOptions = [...prev.options];
            updatedOptions[index] = value;
            return { ...prev, options: updatedOptions };
        });
    };

    const handleCorrectAnswerChange = (value) => {
        setCurrentQuestion((prev) => ({ ...prev, correctAnswer: value }));
    };

    const handleAddQuestion = () => {
        if (
            !currentQuestion.question ||
            currentQuestion.options.length < 2 ||
            currentQuestion.correctAnswer === ""
        ) {
            alert("Please fill out all fields and ensure a correct answer is selected.");
            return;
        }
    
        setQuizQuestions((prev) => {
            const updatedQuestions = [
                ...prev,
                {
                    videoId: videoId, // Link the question to the correct video
                    questions: [{ ...currentQuestion }] // Add question to the 'questions' array
                }
            ];
            console.log("Updated Quiz Questions:", updatedQuestions); // Debugging log
            return updatedQuestions;
        });
    
        // Clear the form after adding the question
        setCurrentQuestion({ question: "", options: [], correctAnswer: "" });
    };

    const handleSubmitQuiz = async () => {
        if (!quizQuestions.length) {
            alert("Please add at least one question before saving the quiz.");
            return;
        }

        try {
            const promises = quizQuestions.map(async (quiz) => {
                const { videoId, questions } = quiz;

                return await axios.post("https://opecstat.com/opecstat_server/quiz/saveQuiz", {
                    videoId,
                    questions, // Now sending questions array
                });
            });

            await Promise.all(promises);
            alert("Quiz saved successfully!");
            setShowModal(false);
            setQuizQuestions([]);
        } catch (error) {
            console.error("Error saving quiz:", error);
            alert("Failed to save some or all quiz questions. Please try again.");
        }
    };

    return (
        <Modal
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
            contentLabel="Quiz Modal"
            style={{
                content: {
                    maxWidth: "600px",
                    margin: "auto",
                },
            }}
        >
            <h2>Add Quiz Question</h2>
            <div className="form-group">
                <label>Question:</label>
                <input
                    type="text"
                    className="form-control"
                    value={currentQuestion.question}
                    onChange={(e) =>
                        setCurrentQuestion((prev) => ({
                            ...prev,
                            question: e.target.value,
                        }))
                    }
                    placeholder="Enter the question"
                />
            </div>
            <div className="form-group mt-3">
                <label>Options:</label>
                {currentQuestion.options.map((option, idx) => (
                    <div key={idx} className="d-flex mb-2">
                        <input
                            type="text"
                            className="form-control me-2"
                            value={option}
                            onChange={(e) => handleOptionChange(idx, e.target.value)}
                            placeholder={`Option ${idx + 1}`}
                        />
                        <button
                            type="button"
                            className="btn btn-danger btn-sm"
                            onClick={() => handleRemoveOption(idx)}
                        >
                            Remove
                        </button>
                    </div>
                ))}
                <button type="button" className="btn btn-secondary mt-2" onClick={handleAddOption}>
                    Add Option
                </button>
            </div>
            <div className="form-group mt-3">
                <label>Correct Answer:</label>
                <select
                    className="form-control"
                    value={currentQuestion.correctAnswer}
                    onChange={(e) => handleCorrectAnswerChange(e.target.value)}
                >
                    <option value="">Select Correct Answer</option>
                    {currentQuestion.options.map((option, idx) => (
                        <option key={idx} value={idx}>
                            {option}
                        </option>
                    ))}
                </select>
            </div>
            <button type="button" className="btn btn-success mt-3" onClick={handleAddQuestion}>
                Add Question
            </button>
            <button type="button" className="btn btn-primary mt-3" onClick={handleSubmitQuiz}>
                Save Quiz
            </button>
            <button type="button" className="btn btn-secondary mt-3" onClick={() => setShowModal(false)}>
                Close
            </button>
        </Modal>
    );
};


const AssignmentModal = ({ showModal, setShowModal, videoId, assignmentQuestions, setAssignmentQuestions }) => {
  const [currentQuestion, setCurrentQuestion] = useState("");

  const handleAddQuestion = () => {
    if (!currentQuestion.trim()) {
      alert("Please fill out the question field.");
      return;
    }

    setAssignmentQuestions((prev) => [...prev, currentQuestion]);

    // Clear the input field after adding the question
    setCurrentQuestion("");
  };

  const handleSubmitAssignment = async () => {
    if (!assignmentQuestions.length) {
      alert("Please add at least one question before saving the assignment.");
      return;
    }

    try {
      const response = await axios.post("https://opecstat.com/opecstat_server/assignment/saveAssignment", {
        videoId,
        questions: assignmentQuestions,
      });

      if (response.status === 201 || response.status === 200) {
        alert("Assignment saved successfully!");
        setShowModal(false);
        setAssignmentQuestions([]);
      } else {
        alert("Failed to save the assignment. Please try again.");
      }
    } catch (error) {
      console.error("Error saving assignment:", error);
      alert("An error occurred while saving the assignment. Please try again.");
    }
  };

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={() => setShowModal(false)}
      contentLabel="Assignment Modal"
      style={{
        content: {
          maxWidth: "600px",
          margin: "auto",
        },
      }}
    >
      <h2>Add Assignment Question</h2>
      <div className="form-group">
        <label>Question:</label>
        <input
          type="text"
          className="form-control"
          value={currentQuestion}
          onChange={(e) => setCurrentQuestion(e.target.value)}
          placeholder="Enter the assignment question"
        />
      </div>
      <button type="button" className="btn btn-success mt-3" onClick={handleAddQuestion}>
        Add Question
      </button>

      <h3 className="mt-4">Assignment Questions</h3>
      {assignmentQuestions.length > 0 ? (
        <ul>
          {assignmentQuestions.map((question, idx) => (
            <li key={idx}>{question}</li>
          ))}
        </ul>
      ) : (
        <p>No questions added yet.</p>
      )}

      <div className="mt-3">
        <button type="button" className="btn btn-primary me-2" onClick={handleSubmitAssignment}>
          Save Assignment
        </button>
        <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>
          Close
        </button>
      </div>
    </Modal>
  );
};




const TextbookModal = ({ showModal, setShowModal, videoId }) => {
    const [file, setFile] = useState(null);
    const [title, setTitle] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    // Handle file selection
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
        setError(""); // Reset error if a file is selected
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!file || !title) {
            setError("Please provide a title and select a PDF file.");
            return;
        }

        const formData = new FormData();
        formData.append("pdfFile", file);
        formData.append("videoId", videoId);
        formData.append("title", title);
        // Log the entries of FormData
for (let [key, value] of formData.entries()) {
    console.log(`${key}: ${value}`);
}

        try {
            setLoading(true);
            setError(""); // Clear previous errors
            setSuccess(""); // Clear success message

            // Reference the already created endpoint to save the textbook
            const response = await axios.post("https://opecstat.com/opecstat_server/textbook//saveTextbook", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            setSuccess("Textbook uploaded successfully.");
            setFile(null); // Clear the file input after successful upload
            setTitle(""); // Clear the title input
        } catch (err) {
            setError("An error occurred while uploading the textbook.");
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
            contentLabel="Textbook Modal"
        >
            <h2>Upload Textbook</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="title">Textbook Title</label>
                    <input
                        type="text"
                        id="title"
                        className="form-control"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Enter textbook title"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="file">Select PDF File</label>
                    <input
                        type="file"
                        id="file"
                        className="form-control"
                        accept=".pdf"
                        onChange={handleFileChange}
                    />
                </div>

                {error && <div className="alert alert-danger mt-3">{error}</div>}
                {success && <div className="alert alert-success mt-3">{success}</div>}

                <button
                    type="submit"
                    className="btn btn-primary mt-3"
                    disabled={loading}
                >
                    {loading ? "Uploading..." : "Upload Textbook"}
                </button>
            </form>

            <button
                type="button"
                className="btn btn-secondary mt-3"
                onClick={() => setShowModal(false)}
            >
                Close
            </button>
        </Modal>
    );
};


const ViewLessons = ({ subCourse }) => {
    const [videoData, setVideoData] = useState([]);
    const [quizModal, setQuizModal] = useState(false);
    const [quizQuestions, setQuizQuestions] = useState([]);
    const [assignmentModal, setAssignmentModal] = useState(false);
    const [assignmentQuestions, setAssignmentQuestions] = useState([]);
    const [textbookModal, setTextbookModal] = useState(false);

    const { result, isResultPending } = useFetch(
        `https://opecstat.com/opecstat_server/video/getVideosBySubCourseId/${subCourse._id}`
    );

    useEffect(() => {
        if (!isResultPending && result) {
            setVideoData(result.videos);
        }
    }, [result, isResultPending]);

    return (
        <div className="row me-1 ms-1">
            <table className="table table-striped table-bordered table-responsive">
                <thead>
                    <tr>
                        <th>S/N</th>
                        <th>Course Name</th>
                        <th>Quiz</th>
                        <th>Assignment</th>
                        <th>Textbook</th>
                    </tr>
                </thead>
                <tbody>
                    {result && videoData.length > 0 ? (
                        videoData.map((video, index) => (
                            <tr key={video._id}>
                                <td>{index + 1}</td>
                                <td>{video.title}</td>
                                <td>
                                    <button
                                        className="btn btn-primary btn-sm me-1"
                                        onClick={() => setQuizModal(true)}
                                    >
                                        Add Quiz
                                    </button>
                                    <QuizModal
                                        showModal={quizModal}
                                        setShowModal={setQuizModal}
                                        videoId={video._id}
                                        quizQuestions={quizQuestions}
                                        setQuizQuestions={setQuizQuestions}
                                    />
                                </td>
                                <td>
                                    <button
                                        className="btn btn-info btn-sm me-1"
                                        onClick={() => setAssignmentModal(true)}
                                    >
                                        Add Assignment
                                    </button>
                                    <AssignmentModal
                                        showModal={assignmentModal}
                                        setShowModal={setAssignmentModal}
                                        videoId={video._id}
                                        assignmentQuestions={assignmentQuestions}
                                        setAssignmentQuestions={setAssignmentQuestions}
                                    />
                                </td>
                                <td>
                                    <button
                                        className="btn btn-success btn-sm me-1"
                                        onClick={() => setTextbookModal(true)}
                                    >
                                        Upload Textbook
                                    </button>
                                    <TextbookModal
                                        showModal={textbookModal}
                                        setShowModal={setTextbookModal}
                                        videoId={video._id}
                                    />
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5">Loading Lessons...</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default ViewLessons;
