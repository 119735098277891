import React, {useEffect, useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/opec10.png';
import loader1 from '../../assets/images/03-42-11-849_512.gif';
import '../../assets/styles/font_style.css';
import axios from 'axios';
import useCheckInternet from '../generalComponents/checkOnline';

const ResetPassword = ({handleResetView}) => {
    const [email, setEmail] = useState('');
    const [role, setRole] = useState(null)
    const [currentView, setCurrentView] = useState('view1');
    const [resetCode, setResetCode] = useState('');
    const [password, setPassword] = useState('');
    const [successMessage, setSuccessMessage] = useState(false);
    const [error, setError] = useState({});
    const [isPending, setIsPending] = useState(false);
    const [serverError, setServerError] = useState(null);
    const [hideTop, setHideTop] = useState(false);
    const [dResetCode, setDResetCode] = useState(null);
    const navigate = useNavigate();
    
    const validateField = (field, value) => {
        let error = '';
        if (value !== undefined) {
            if (field === 'email') {
                // Basic email validation (adjust as needed)
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailRegex.test(value)) {
                    error = 'Invalid email address';
                }
            } else if (field === 'resetCode') {
                if (value.length < 6) {
                    error = 'Reset code should be 6 characters long';
                }
            } else if (field === 'password') {
                if (value.length < 6) {
                    error = 'password should be at least 6 characters long';
                }
            }
        }
        setError((prevErrors) => ({ ...prevErrors, [field]: error }));
    };

    const isOnline = useCheckInternet();

    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        setServerError(null)
        if (!isOnline) {
            setServerError('You are offline. Please check your internet connection and try again.');
            return;
        } else if (!email || email.trim() === "") {
            setServerError('Please enter an email address.');
            return;
        }

        setIsPending(true);
        validateField('email', email);
        const hasErrors = Object.values(error).some((error) => error !== '');
        if (hasErrors) {
            setServerError('Error. Please correct the field and try again.');
            setIsPending(false);
            return;
        }

        try {
            let response = null
            let data = { email };
            if(role === "student"){
                response = await axios.post('https://www.opecstat.com/opecstat_server/user/request-password-reset', JSON.stringify(data), {
                    headers: { 'Content-Type': 'application/json' },
                });
            }else if(role === "instructor"){
                response = await axios.post('https://www.opecstat.com/opecstat_server/instructor/request-password-reset', JSON.stringify(data), {
                    headers: { 'Content-Type': 'application/json' },
                });
            }else{
                alert("Please select a role");
                setIsPending(false)
                return;
            }
            console.log(response)
            if (response.status === 200) {
                setIsPending(false);
                setSuccessMessage(true);
                setCurrentView("view2");
                setDResetCode(response.data.resetCode)
                console.log("Reset Code: ", response.data.resetCode)
                /*setTimeout(() => {
                    setSuccessMessage(false);
                }, 4000);*/
            } else {
                setIsPending(false);
                setServerError(response?.data?.message || 'An error occurred. Please try again.');
                throw new Error(response.data.message);
            }
        } catch (error) {
            setServerError(error.response?.data?.message || 'An error occurred. Please try again.');
            setIsPending(false);
        }
    };

    const handleResetCodeSubmit = async (e) => {
        e.preventDefault();
        setServerError(null)
        setIsPending(true);
        validateField('resetCode', resetCode);
        if (Object.values(error).some((error) => error !== '')) {
            setServerError('Error. Please correct the reset code.');
            return;
        }

        try {
            let response = null
            let data = { email, resetCode };
            if(role === "student"){
                response = await axios.post('https://www.opecstat.com/opecstat_server/user/validate-reset-code', JSON.stringify(data), {
                    headers: { 'Content-Type': 'application/json' },
                });
            }else if(role === "instructor"){
                response = await axios.post('https://www.opecstat.com/opecstat_server/instructor/validate-reset-code', JSON.stringify(data), {
                    headers: { 'Content-Type': 'application/json' },
                });
            }else{
                alert("Please select a role");
                setIsPending(false)
                return;
            }
            console.log(response)
            if (response.status === 200) {
                setSuccessMessage(true);
                setIsPending(false);
                setCurrentView("view3");
                setTimeout(() => {
                    setSuccessMessage(false);
                }, 4000);
            } else {
                setIsPending(false);
                setServerError(response?.data?.message || 'An error occurred. Please try again.');
                throw new Error(response.data.message);
            }
        } catch (error) {
            setServerError(error.response?.data?.message || 'An error occurred. Please try again.');
            setIsPending(false);
        }
    };

    const handlePasswordReset = async (e) => {
        e.preventDefault();
        
        // Clear previous error and pending states
        setServerError(null);
        setIsPending(true);
    
        // Validate the password field
        validateField('password', password);
    
        // Check if there are validation errors
        if (Object.values(error).some((err) => err !== '')) {
            setServerError('Error. Please check password.');
            setIsPending(false); // Reset pending state
            return; // Early return if there are validation errors
        }
    
        try {
            let response = null
            // Prepare the data for the request
            let data = { email, resetCode, password };
    
            // Make the API request
            if(role === "student"){
                response = await axios.post('https://www.opecstat.com/opecstat_server/user/reset-password', 
                    JSON.stringify(data), 
                    { headers: { 'Content-Type': 'application/json' } }
                );
            }else if(role === "instructor"){
                response = await axios.post('https://www.opecstat.com/opecstat_server/instructor/reset-password', 
                    JSON.stringify(data), 
                    { headers: { 'Content-Type': 'application/json' } }
                );
            }else{
                alert("Please select a role");
                setIsPending(false)
                return;
            }
    
            // If the request is successful
            if (response.status === 200) {
                setIsPending(false);
                console.log("Response:", response.data.message);
                setSuccessMessage(true)
                setTimeout(() => {
                    navigate(0)
                }, 3000);
                 // Navigate back to the login or reset view
            } else {
                // If the request fails (non-200 status)
                setIsPending(false);
                setServerError(response?.data?.message || 'An error occurred. Please try again.');
            }
        } catch (error) {
            // Handle network or other request errors
            setIsPending(false);
            setServerError(error.response?.data?.message || 'An error occurred. Please try again.');
        }
    };
    

    const getViewComponent = () => {
        switch (currentView) {
            case 'view1':
                return (
                    <form className='mt-1'>
                    {successMessage && <div className="alert alert-success px-4 py-2 rounded-md">Reset Code has been sent. Please enter it below.</div>}
                        <div className='mb-2'>
                            <input
                                type="email"
                                className="rounded-lg px-6 py-3 w-full"
                                id="email"
                                placeholder="Email Address"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    validateField('email', e.target.value);
                                }}
                                onBlur={() => validateField('email', email)}
                            />
                            {error.email && <p className="text-danger mt-1 mb-3 ml-2">{error.email}</p>}
                        </div>
                        <div className='mb-4'>
                            <select 
                                name="role" 
                                className="rounded-lg px-6 py-3 w-full text-dark" 
                                value={role} 
                                onChange={(e)=>{setRole(e.target.value)}} 
                                onBlur={() => validateField('role', role)}
                                id="role"
                                placeholder="Role"
                            >
                                
                                <option>Select Role</option>
                                <option value="student">Student</option>
                                <option value="instructor">Instructor</option>
                            </select>
                        </div>
                        <div className='mb-2 d-grid'>
                            <button onClick={handleEmailSubmit} type="submit" style={{ color: "#fff", backgroundColor: "#FF7518" }} className='btn btn-block btn-wangin py-2'>
                                Send Reset Code
                            </button>
                        </div>
                    </form>
                );
            case 'view2':
                return (
                    <form className='mt-1'>
                        {successMessage && <div className="alert alert-success px-4 py-2 rounded-md">{/*Reset Code is <span className='badge bg-dark p-2 fs-5'>{dResetCode}</span>.*/}Your reset code has been sent to your email address.<br/> Please enter it below. It expires in 1 hour</div>}
                        <div className='mb-2'>
                            <input
                                type="text"
                                className="rounded-lg px-6 py-3 w-full"
                                id="resetCode"
                                placeholder="Enter Reset Code"
                                value={resetCode}
                                onChange={(e) => {
                                    setResetCode(e.target.value);
                                    validateField('resetCode', e.target.value);
                                }}
                                onBlur={() => validateField('resetCode', resetCode)}
                            />
                            {error.resetCode && <p className="text-danger mt-1 mb-3 ml-2">{error.resetCode}</p>}
                        </div>
                        <div className='mb-2 d-grid'>
                            <button onClick={handleResetCodeSubmit} type="submit" style={{ color: "#fff", backgroundColor: "#FF7518" }} className='btn btn-block btn-wangin py-2'>
                                Verify Reset Code
                            </button>
                        </div>
                    </form>
                );
                case 'view3':
                return (
                    <form className='mt-1'>
                    {successMessage && <div className="alert alert-success px-4 py-2 rounded-md">Your password has been reset. You would be redirected to login shortly</div>}
                        <div className='mb-2'>
                            <input
                                type="password"
                                className="rounded-lg px-6 py-3 w-full"
                                id="password"
                                placeholder="Enter New Password"
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    validateField('password', e.target.value);
                                }}
                                onBlur={() => validateField('password', password)}
                            />
                            {error.password && <p className="text-danger mt-1 mb-3 ml-2">{error.password}</p>}
                        </div>
                        <div className='mb-2 d-grid'>
                            <button onClick={handlePasswordReset} type="submit" style={{ color: "#fff", backgroundColor: "#FF7518" }} className='btn btn-block btn-wangin py-2'>
                                Set New password
                            </button>
                        </div>
                    </form>
                );
            default:
                navigate("/login");
                return null;
        }
    };

    return (
        <center>
            {isPending && (
                <div className='h-100 text-xxl mt-10'>
                    <img src={loader1} alt='loader' />
                    <br />
                    <p className='fs-2' style={{ color: "black" }}>Processing...</p>
                </div>
            )}
            {!isPending && (
                <div style={{ width: '70%' }}>
                    {!hideTop && (
                        <center>
                            <Link to="/"><h3 className='w-25 mt-5'><img src={logo} alt='opecstat logo' /></h3></Link>
                            <p className='my-5 text-xl font-mono' style={{ color: "black" }}>
                                Opecstat offers a seamless and structured way to learn and improve your digital skills.
                            </p>
                        </center>
                    )}
                    {serverError && <div className="alert alert-danger px-4 py-2 rounded-md">{serverError}</div>}
                    {getViewComponent()}
                </div>
            )}
        </center>
    );
};

export default ResetPassword;
