import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import loader1 from '../../assets/images/03-42-11-849_512.gif'
import axios from 'axios';
import defaultImage from '../../assets/images/userImage/defaultUserImage.png'

const OnboardForm = ({userData, onProfileUpdate, screenWidth}) => {
    let nameArray = userData.name.split(" ")
    const arrayToStringDisplay = (array) =>{
        if(Array.isArray(array) && array.length>0){
            return array.join(",")
        }else{
            return "";
        }
    }
    const [currentStep,setCurrentStep] = useState(1)
    const [fname, setFName] = useState(nameArray[0])
    const [lname, setLName] = useState(nameArray[1])
    const [email, setEmail] = useState(userData.email)
    const [country, setCountry] = useState(userData.country)
    const [skillsPossessed, setSkillsPossessed] = useState(arrayToStringDisplay(userData.skills_possessed))
    const [skillsRequired, setSkillsRequired] = useState(arrayToStringDisplay(userData.skills_required))
    const [proofOfWork, setProofOfWork] = useState(arrayToStringDisplay(userData.proof_of_work))
    const [isPending, setIsPending] = useState(false)
    const [error, setError] = useState({});
    const [serverError, setServerError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(false);
    const [progress, setProgress] = useState(0);
    const [profileImage, setProfileImage] = useState(userData.profileImage);
    const [profileImagePreview, setProfileImagePreview] = useState(defaultImage); 
    const [saveReminder, setSaveReminder] = useState(false)
    useEffect(() => {
        if(userData.profileImage !== null && userData.profileImage !== ''){
            setProfileImagePreview(`http://swaptime.co/public/images/userProfileImages/${userData.profileImage}`)
        }
    }, [profileImage,userData.profileImage]);
    
    
    
    const validateField = (field, value) => {
        let error = '';
        if(value !== undefined){
            if (field === 'fname') {
                if (value.trim() === '') {
                    error = 'First Name is required';
                }
            }else if (field === 'lname') {
                if (value.trim() === '') {
                    error = 'Last Name is required';
                }
            } else if (field === 'email') {
                // Basic email validation (adjust as needed)
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailRegex.test(value)) {
                    error = 'Invalid email address';
                }
            } else if (field === 'country') {
                if (value.trim() === '') {
                    error = 'Country is required';
                }
            } else if (field === 'skillsPossessed') {
                if (value.trim() === '') {
                    error = 'Skill Possessed is required';
                }
            } else if (field === 'skillsRequired') {
                if (value.trim() === '') {
                    error = 'Skill Required is required';
                }
            } else if (field === 'proofOfWork') {
                if (value.trim() === '') {
                    error = 'ProofOfWork is required';
                }
            } 
        }
        setError((prevErrors) => ({ ...prevErrors, [field]: error }));
    };

    /*useEffect(() => {
        if (userData) {
            let link = `http://swaptime.co/public/images/userProfileImages/${userData.profileImage}`
            setFName(userData.name.split(' ')[0]);
            setLName(userData.name.split(' ')[1]);
            setEmail(userData.email);
            setCountry(userData.country);
            setSkillsPossessed(userData.skills_possessed.join(', '));
            setSkillsRequired(userData.skills_required.join(', '));
            setProofOfWork(userData.proof_of_work.join(', '));
            //setProfileImagePreview( link || defaultImage);
        }
    }, [userData]);*/

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/') && file.size <= 2 * 1024 * 1024) { // 2MB limit
            setProfileImage(file);
            console.log(file)
            console.log(profileImage)
            const reader = new FileReader();
            reader.onloadend = () => {
                setProfileImagePreview(reader.result);
                setSaveReminder(true)
            };
            reader.readAsDataURL(file);
        } else {
            alert('Please upload a valid image file not exceeding 2MB.');
        }
    };

    const handleFormSubmit = async (e) =>{
        e.preventDefault()
        const hasErrors = Object.values(error).some((error) => error !== '');
        if (hasErrors) {
        return;
        }
        setIsPending(true)
        const formData = new FormData();
        formData.append('name', `${fname} ${lname}`);
        formData.append('email', email);
        formData.append('country', country);
        formData.append('skillsPossessed', skillsPossessed);
        formData.append('skillsRequired', skillsRequired);
        formData.append('proofOfWork', proofOfWork);
        if (profileImage) {
            formData.append('profileImage', profileImage);
        }

        try {
            const response = await axios.post('https://www.swaptime.co/swaptime_server/user/updateUser', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            setIsPending(false);
            if (response.status === 200) {
                console.log(response.message)
                console.log('User Registered');
                setServerError(null)
                setSaveReminder(false)
                setSuccessMessage(true)
                setTimeout(() => {
                    setSuccessMessage(false)
                }, 3000);
                onProfileUpdate()
            }else{
                setServerError(response.message)
            }
        } catch (error) {
            setIsPending(false);
            setServerError(error)
            console.error(error)
        }

    }
    useEffect(() => {
        const fields = [fname, lname, email, country, skillsPossessed, skillsRequired, proofOfWork, profileImagePreview];
        const filledFields = fields.filter(field => field && field.trim() !== '').length;
        const totalFields = fields.length;
        setProgress((filledFields / totalFields) * 100);
    }, [fname, lname, email, country, skillsPossessed, skillsRequired, proofOfWork, profileImagePreview]);

    const handleImagePlaceholderClick = () =>{
        document.getElementById('imageUploadInput').click();
    }
    return (
        <section className="mt-4 mb-4" style={{color:"black"}}>
            <center>
                {isPending && <div className='h-screen text-slate-800 text-5xl mt-10' style={{width:"90%"}}><img src={loader1} alt='loader' /><br/>Saving Your Profile</div>}
                {
                    !isPending && 
                    <div style={{width:'70%'}}>
                        <form onSubmit={handleFormSubmit}  className='text-slate-800'>
                            <div className='mt-2 mb-4'>
                                <p className='text-primary text-4xl font-bold text-justify'>Complete Your Profile</p>
                            </div>
                            <div className="progress-bar bg-secondary rounded-full h-6 mb-4">
                                <div className="bg-primary h-6 rounded-full transition-all duration-500 pb-2 text-white font-bold" style={{ width: `${progress}%` }}><center>{Math.ceil(progress)}%</center></div>
                            </div>
                            {successMessage && <div className=" alert alert-success px-4 py-2 rounded-md">Profile Saved!</div>}
                            {serverError && <div className=" alert alert-danger px-4 py-2 rounded-md">{serverError.message}</div>}
                            {saveReminder && <div className=" alert alert-warning px-4 py-2 rounded-md">Remember to Save changes</div>}
                            <div className='row'>
                                <div className='mb-3 row'>
                                    <div className='col-md-6'>
                                        <input 
                                            type="file" 
                                            className="hidden" 
                                            placeholder="Upload Image"
                                            name="profileImage"
                                            accept='image/*'
                                            id="imageUploadInput"
                                            onChange={handleImageChange}
                                        />
                                        <div className='p-1 bg-light rounded-lg' >  
                                            <img src={profileImagePreview} onClick={handleImagePlaceholderClick} alt="Profile Preview" className="h-75 shadow rounded-circle cursor-pointer" style={{maxWidth:"150px", maxHeight:"150px"}} /> 
                                            Choose Picture
                                        </div> 
                                        
                                        {/*error.fname && <p style={{float:"left"}} className="text-red-500 mt-1 mb-3 ml-2">{error.fname}</p>*/}
                                    </div>
                                    <div className='col-md-6 mb-2'>
                                        <div className='col-md-12 mb-4'>
                                            <input 
                                                type="text" 
                                                id="fname"
                                                value={fname}
                                                onChange={(e) => {
                                                    setFName(e.target.value);
                                                    validateField('fname', e.target.value);
                                                }}
                                                onBlur={() => validateField('fname', fname)}
                                                className="rounded-lg px-6 py-4 w-full d-block" 
                                                placeholder="First Name"
                                                disabled = "disabled"
                                            />
                                            {/*error.fname && <p style={{float:"left"}} className="text-red-500 mt-1 mb-3 ml-2">{error.fname}</p>*/}
                                        </div>
                                        <div className='col-md-12'>
                                            <input 
                                                type="text" 
                                                id="lname"
                                                value={lname}
                                                onChange={(e) => {
                                                    setLName(e.target.value);
                                                    validateField('lname', e.target.value);
                                                }}
                                                onBlur={() => validateField('lname', fname)}
                                                className="rounded-lg px-6 py-4 w-full d-block" 
                                                placeholder="Last Name"
                                                disabled = "disabled"
                                            />
                                            {/*error.fname && <p style={{float:"left"}} className="text-red-500 mt-1 mb-3 ml-2">{error.fname}</p>*/}
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='mb-2 col-md-6'>
                                        <input 
                                            type="email" 
                                            id="email"
                                            value={email}
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                                validateField('email', e.target.value);
                                            }}
                                            onBlur={() => validateField('email', email)}
                                            className="rounded-lg px-6 py-3 w-full text-slate-600" 
                                            placeholder="Email Address"
                                            disabled = "disabled"
                                        />
                                        {/*error.email && <p style={{float:"left"}} className="text-red-500 mt-1 mb-3 ml-2">{error.email}</p>*/}
                                    </div>
                                    <div className='mb-2 col-md-6'>
                                        <select 
                                            name="country" 
                                            className="rounded-lg px-6 py-3 w-full text-gray-600" 
                                            value={country} 
                                            onChange={(e)=>{setCountry(e.target.value)}} 
                                            onBlur={() => validateField('country', country)}
                                            id="country"
                                            placeholder="Country of Residence"
                                            disabled = "disabled"
                                        >
                                            <option value="" disabled>Select a Country</option>
                                            <option value="US">United States</option>
                                            <option value="CA">Canada</option>
                                            <option value="MX">Mexico</option>
                                            <option value="BR">Brazil</option>
                                            <option value="AR">Argentina</option>
                                            <option value="RU">Russia</option>
                                            <option value="DE">Germany</option>
                                            <option value="FR">France</option>
                                            <option value="GB">United Kingdom</option>
                                            <option value="IT">Italy</option>
                                            <option value="CN">China</option>
                                            <option value="IN">India</option>
                                            <option value="JP">Japan</option>
                                            <option value="KR">South Korea</option>
                                            <option value="ID">Indonesia</option>
                                            <option value="NG">Nigeria</option>
                                            <option value="ZA">South Africa</option>
                                            <option value="ET">Ethiopia</option>
                                            <option value="EG">Egypt</option>
                                            <option value="AU">Australia</option>
                                        </select>
                                        {/*error.country && <p style={{float:"left"}} className="text-red-500 mt-1 mb-3 ml-2">{error.country}</p>*/}
                                    </div>
                                </div>
                                <div className='mb-2 mt-2'>
                                    <div className='float-start'>
                                        Skills you Possess <small>(If more than one, separate each skill by a comma)</small>
                                    </div><br/>
                                    
                                    <textarea 
                                        className='rounded-lg px-6 py-3 w-full' 
                                        value={skillsPossessed} 
                                        onChange={(e)=>{setSkillsPossessed(e.target.value)}}
                                        onBlur={() => validateField('skillsPossessed', skillsPossessed)}
                                    ></textarea>
                                </div>
                                <div className='mb-2 mt-2 items-start'>
                                    <div className='float-start'>
                                        Skills you require <small>(If more than one, separate each skill by a comma)</small>
                                    </div><br/>
                                    <textarea 
                                        className='rounded-lg px-6 py-3 w-full' 
                                        value={skillsRequired} 
                                        onChange={(e)=>{setSkillsRequired(e.target.value)}}
                                        onBlur={() => validateField('skillsRequired', skillsRequired)}
                                    ></textarea>
                                </div>
                                <div className='mb-2 mt-2 items-start'>
                                    <div className='float-start'>
                                        Proof of Work(url) <small>(If more than one, separate each skill by a comma)</small>
                                    </div><br/>
                                    <textarea 
                                        className='rounded-lg px-6 py-3 w-full' 
                                        value={proofOfWork} 
                                        onChange={(e)=>{setProofOfWork(e.target.value)}}
                                        onBlur={() => validateField('proofOfWork', proofOfWork)}
                                    ></textarea>
                                </div>
                                
                                <div className='mb-2 mt-1'>
                                    <button type="button" style={{backgroundColor:"black", padding:"4px", paddingBottom:"6px", borderRadius:"10px", color:"#fff" }} onClick={handleFormSubmit} className='border-1 border-solid border-gray-800 bg-orange-200 hover:bg-zinc-900 text-white w-full px-3 pt-2'>Save Profile</button>
                                </div>
                            </div>
                        </form>  
                    </div>
                }
            </center>
        </section>
    );
}

export default OnboardForm;