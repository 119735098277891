import { useState, useEffect } from "react";
import axios from "axios";

const useFetch = (url) => {
    const [result, setResult] = useState([]);
    const [isResultPending, setIsResultPending] = useState(true);
    const [resultError, setResultError] = useState(null);

    useEffect(() => {
        const abortController = new AbortController();

        const fetchData = async () => {
            let retries = 3; // Retry logic
            while (retries > 0) {
                try {
                    const response = await axios.get(url, {
                        signal: abortController.signal,
                        timeout: 30000, // Set timeout to 10 seconds
                    });

                    if (response.status === 200) {
                        setResult(response.data);
                        setIsResultPending(false);
                        setResultError(null);
                        return; // Exit loop on success
                    } else {
                        throw new Error(`Failed with status: ${response.status}`);
                    }
                } catch (error) {
                    if (axios.isCancel(error)) {
                        console.log("Fetch Aborted");
                        return; // Exit on manual cancellation
                    } else {
                        console.error(error.message);
                        retries -= 1; // Decrement retries
                        if (retries === 0) {
                            setResultError(error.message || "An error occurred");
                            setIsResultPending(false);
                        }
                    }
                }
            }
        };

        fetchData();

        return () => abortController.abort(); // Properly abort fetch on cleanup
    }, [url]);

    return { result, isResultPending, resultError };
};

export default useFetch;
