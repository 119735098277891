import React, { useState, useRef, useEffect } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import useFetch from "../../custom_hooks/useFetch";

const ChatComponent = ({ user, video }) => {
  const [message, setMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [maxMessages, setMaxMessages] = useState(0);
  const chatEndRef = useRef(null);
  const { result, isResultPending, resultError } = useFetch(`https://opecstat.com/opecstat_server/chat/getMessages/${user._id}`);

  useEffect(() => {
    if (!isResultPending && result?.chats) {
      const formattedChats = result.chats.map(chat => ({
        text: chat.message,
        sender: chat.chatBotStatus ? "bot" : "user",
      }));
      setChatHistory(formattedChats);
      setMaxMessages(result.maxMessages);
      console.log("max: ", maxMessages);
    }
  }, [result, isResultPending]);

  const handleSendMessage = async () => {
    if (!message.trim()) return;

    const userMessage = { text: message, sender: "user" };
    setChatHistory(prev => [...prev, userMessage]);
    setMessage("");
    setIsLoading(true);

    try {
      const data = { message, userId: user._id, videoId: video._id };
      const response = await axios.post("https://opecstat.com/opecstat_server/chat/addMessage", JSON.stringify(data), {
        headers: { "Content-Type": "application/json" }
      });

      const botMessage = { text: response.data.reply, sender: "bot" };
      setChatHistory(prev => [...prev, botMessage]);
    } catch (error) {
      console.error("Error sending message:", error);
      const errorMessage = { text: "Error occurred.", sender: "bot" };
      setChatHistory(prev => [...prev, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  const formatMessage = (message) => {
    const codeBlockRegex = /```([\s\S]*?)```/g;
    const parts = message.split(codeBlockRegex);

    return parts.map((part, index) => {
      if (index % 2 === 1) {
        return (
          <SyntaxHighlighter language="javascript" style={docco} key={index}>
            {part}
          </SyntaxHighlighter>
        );
      }
      return <span key={index}>{part}</span>;
    });
  };

  const userMessagesSent = chatHistory.filter(chat => chat.sender === "user").length;
  const messagesLeft = maxMessages - userMessagesSent;

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatHistory]);

  return (
    <div className="chat-component">
      <div className="text-center mb-3">
        <h4>Have any questions?</h4>
        <p>Feel free to ask anything related to the course!</p>
        {isResultPending && <div className="alert alert-warning w-100 m-1"><b>Loading Data...<br/>Please wait.</b></div>}
      </div>
      {
        !isResultPending && user.sub_status !== 50 && user.sub_status < 3 ? (
          <div className="alert alert-info mb-2">
            <center>
              <p>You have sent <strong>{userMessagesSent}</strong> messages.<br />You have <strong>{messagesLeft}</strong> messages left this month.</p>
              {messagesLeft <= 5 && (
                <small className="text-muted">
                  You’re reaching your limit. Subscribe to remove this limitation. Message count resets every month.
                </small>
              )}
            </center>
          </div>
        ) : (
          <div></div>
        )
      }
      {
          resultError && <div>{JSON.stringify(resultError)}</div>
      }

      <div className="chat-history mb-3" style={{ maxHeight: "300px", overflowY: "auto", border: "1px solid #ccc", padding: "10px" }}>
        {chatHistory.map((chat, index) => (
          <div key={index} className={`chat-message ${chat.sender}`}>
            {chat.sender === "user" ? (
              <div className="d-flex justify-content-end mb-2">
                <div className="p-3 bg-success text-white rounded" style={{ minWidth: "40%" }}>
                  <small className="fw-bold">You:</small>
                  <br />
                  {chat.text}
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-start mb-2">
                <div className="p-3 bg-light text-dark rounded" style={{ minWidth: "40%" }}>
                  <small className="fw-bold">Lucy:</small>
                  <br />
                  {formatMessage(chat.text)}
                </div>
              </div>
            )}
          </div>
        ))}
        {isLoading && <div className="alert alert-info w-100 m-1">Loading reply...</div>}
        <div ref={chatEndRef} />
      </div>

      <div className="d-flex">
        <input
          type="text"
          className="form-control me-2"
          placeholder="Type your question here..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          disabled={messagesLeft <= 0}
        />
        <Button variant="primary" onClick={handleSendMessage} disabled={messagesLeft <= 0}>
          Send
        </Button>
      </div>
    </div>
  );
};

export default ChatComponent;
