import React, { useEffect, useState } from "react";
import useFetch from "../custom_hooks/useFetch";

const ViewCourses = ({ instructorData, renderView }) => {
    const [mainCourses, setMainCourses] = useState([]);
    const { result, isResultPending, resultError } = useFetch(
        `https://opecstat.com/opecstat_server/course/getCourseByInstructorId/${instructorData._id}`
    );
    
    if(resultError){
        alert(JSON.stringify(resultError))
    }
    ///console.log("Result is ", JSON.stringify(result))
    useEffect(() => {

        if (!isResultPending) {
            //console.log("Result is ", JSON.stringify(result,null,2))
            const filteredCourses = result.courses.filter(
                (course) => course.main_c_id === null
            );
            
            //console.log("Filtered Course", JSON.stringify(filteredCourses))
            setMainCourses(filteredCourses);
        }
    }, [result, isResultPending]);

    return (
        <div className="row me-1 ms-1">
            <table className="table table-striped table-bordered table-responsive">
                <thead>
                    <tr>
                        <th>S/N</th>
                        <th>Course Name</th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {result && mainCourses.length > 0 ? (
                        mainCourses.map((course) => (
                            <tr key={course._id}>
                                <td></td>
                                <td>{course.title}</td>
                                <td>
                                    <button className="btn btn-primary btn-sm">Edit</button>
                                </td>
                                <td>
                                    <button className="btn btn-warning btn-sm" onClick={()=>renderView('sub_courses', course)}>View Sub Courses</button>
                                </td>
                                <td>
                                    <button className="btn btn-danger btn-sm">Delete</button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="4">Loading Courses...</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default ViewCourses;
