import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Homepage from './pages/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Register from './pages/Register';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import InstructorDashboard from './pages/instructor/Dashboard';
import ThankYouPage from './pages/ThankYou';
import PrivateRoute from './components/auth/PrivateRoute';
import Webinar from './pages/Webinar';
import Lesson from './pages/Lesson';
import LearnPython from './pages/LearnPython';

function App() {
  useEffect(() => {
    document.title = "Opecstat Academy: No.1 Learning Academy";
  }, []);

  return (
    <Router>
      <body className='content' >
        <Routes>
          <Route index element={<Homepage />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/register/:freeStatus" element={<Register />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/login/:id" element={<Login />} />
          <Route exact path="/lesson/:id" element={<Lesson/>}/>
          <Route exact path="/webinar/:id" element={<Webinar/>}/>
          <Route exact path="/thankyou" element={<ThankYouPage />} />
          <Route exact path="/learn-python" element={<LearnPython />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
          path="/instructor/dashboard"
          element={
            <PrivateRoute>
              <InstructorDashboard />
            </PrivateRoute>
          }
        />
        </Routes>
      </body>
    </Router>
  );
}

export default App;
