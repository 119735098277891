import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import YouTubeEmbed from './utilityComponents/YoutubeEmbed';
import logo from '../../assets/images/opec10.png';
import useFetch from '../custom_hooks/useFetch';
import ResourceControlComponent from './utilityComponents/ResourceControl';
import FlutterwaveSubscription from '../paymentComponents/flutterwaveSubscription';

const WebinarView = ({ renderView = null, userData = null, videoData }) => {
    const [videoId, setVideoId] = useState(null);
    const [timer, setTimer] = useState(1200); // 20 minutes in seconds
    const [isButtonVisible, setIsButtonVisible] = useState(true); // State to manage button visibility

    const {
        result,
        isResultPending,
        resultError,
    } = useFetch(videoId ? `https://opecstat.com/opecstat_server/learnResource/getLearnResourceByVideoId/${videoId}` : null);

    useEffect(() => {
        // Check if videoData._id is available before setting videoId
        if (videoData && videoData._id) {
            setVideoId(videoData._id);
        }
    }, [videoData]); // Watch videoData for changes

    useEffect(() => {
        // Countdown timer logic
        const timerInterval = setInterval(() => {
            setTimer((prevTime) => {
                if (prevTime <= 0) {
                    clearInterval(timerInterval); // Stop the timer when it hits zero
                    setIsButtonVisible(false); // Hide the button when the timer reaches zero
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        // Cleanup the interval when the component is unmounted
        return () => clearInterval(timerInterval);
    }, []);

    const handleSuccess = (message) => {
        alert(message);
    };

    const handleFailure = (message) => {
        alert(message);
    };

    // Convert seconds into a MM:SS format
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secondsLeft = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(secondsLeft).padStart(2, '0')}`;
    };

    return (
        <>
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                {videoData && <h1 className="h3 mb-0 text-gray-800">{videoData.title}</h1>}
            </div>

            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-body">
                            <div className="milestone_area">
                                {!videoData && (
                                    <center>
                                        <img
                                            src={logo}
                                            alt="logo"
                                            style={{ width: '30%', height: '25%', opacity: '25%' }}
                                        />
                                        <br />
                                        <b>Loading Lesson...</b>
                                    </center>
                                )}
                                {videoData && <YouTubeEmbed videoData={videoData} />}
                            </div>
                        </div>
                    </div>
                    
                    <center className='text-dark'>
                        {/* Countdown Timer Display */}
                        <div style={{ textAlign: 'center', marginBottom: '20px', width:"80%" }}>
                            <h4>This exclusive offer is available for a limited time. Act fast!</h4>
                            <h2>{formatTime(timer)}</h2>
                        </div>

                        {/* Marketing Copy */}
                        <div style={{ textAlign: 'center', marginBottom: '20px', width:"80%" }}>
                            <h3>Exclusive Offer Just for You!</h3>
                            <p>
                                You've already taken the first step by attending this webinar—now, we want to reward your commitment. 
                                We're currently offering our full program at a massive discount—₦24,500, down from the original price of ₦67,000.
                            </p>
                            <p>
                                But because you've been dedicated enough to take part in this webinar, we're giving you an even more exclusive offer. 
                                For a very limited time, you can enroll for just <strong>₦10,000</strong>!
                            </p>
                            <p>
                                This is an opportunity that's too good to miss, but we want only the most serious and committed individuals to seize it. 
                                That's why we've set a timer—once it hits zero, the offer is gone.
                            </p>
                            <p>
                                Don't wait—click the button below to secure your spot before time runs out!
                            </p>
                        </div>

                        {/* Enrollment Button */}
                        {isButtonVisible && (
                            <center>
                                <FlutterwaveSubscription
                                    prefilledEmail=""
                                    prefilledName=""
                                    userId=""
                                    amount={10000} // Special promo amount
                                    onSuccess={handleSuccess}
                                    onFailure={handleFailure}
                                    buttonDisplayText="Claim Your Spot for Just ₦10,000"
                                />
                            </center>
                        )}
                        {!isButtonVisible && (
                            <center>
                                <p>The exclusive offer has expired. Please try again later.</p>
                            </center>
                        )}
                    </center>
                </div>
            </div>
        </>
    );
};

export default WebinarView;
