import React, { useState, useEffect } from 'react';
import { Card, Accordion, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const WatchedLessons = ({ userData }) => {
    const [videosGrouped, setVideosGrouped] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const videosWatched = userData?.vids_watched || [];

                const groupedVideos = videosWatched.reduce((acc, video) => {
                    const mainCourseId = video.main_course_id?._id || 'Uncategorized';
                    if (!acc[mainCourseId]) {
                        acc[mainCourseId] = {
                            mainCourseTitle: video.main_course_id?.title || 'Uncategorized',
                            videos: []
                        };
                    }
                    acc[mainCourseId].videos.push(video);
                    return acc;
                }, {});

                setVideosGrouped(groupedVideos);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching user videos:', error);
                setLoading(false);
            }
        };

        fetchVideos();
    }, [userData]);

    if (loading) {
        return <Spinner animation="border" role="status"><span className="sr-only">Loading...</span></Spinner>;
    }

    return (
        <div className="container mt-4">
            <h3>Watched Videos</h3>
            <Accordion defaultActiveKey="0">
                {Object.keys(videosGrouped).map((mainCourseId, index) => (
                    <Accordion.Item eventKey={String(index)} key={mainCourseId} className="mb-3 shadow-sm">
                        <Accordion.Header>
                            <span className='me-2'>{videosGrouped[mainCourseId].mainCourseTitle}</span>
                            <span className="badge badge-info">{videosGrouped[mainCourseId].videos.length} Videos</span>
                        </Accordion.Header>
                        <Accordion.Body>
                            {videosGrouped[mainCourseId].videos.map(video => (
                                <div key={video._id} className="p-2 border-bottom mb-2">
                                    <h5 className="mb-1">{video.title}</h5>
                                    <p className="mb-2 text-muted">{video.desc || 'No description available'}</p>
                                    <Link to={`/lesson/${video._id}`} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-secondary">
                                        Watch Video
                                    </Link>
                                </div>
                            ))}
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </div>
    );
};

export default WatchedLessons;
