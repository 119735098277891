import '../assets/styles/style.css';
import '../assets/styles/responsive.css';
import '../assets/styles/font-awesome.min.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import RegForm from "../components/auth/RegForm"
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios'


const Register = () =>{ 
    const navigate = useNavigate();
    useEffect(() => {
      const fetchUserData = async () => {
        try {
            const token = localStorage.getItem('token');
            //console.log("This is the token",token)
            const config = {
                headers: {
                  Authorization: `Bearer ${token}`, // Include JWT token in Authorization header
                  'Content-Type': 'application/json', // Optional, depending on your API
                }
            };
            const response = await axios.get('https://www.swaptime.co/swaptime_server/user/getUser', config);
            if(response.status === 200){
                navigate('/dashboard', { state: { serverError: 'Please log in to access the dashboard.' } });
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
      }
      fetchUserData();
    });
    return (
            <body className='h-full min-h-screen bg-slate-700'>
                <RegForm/>
            </body>
    )
}

export default Register;