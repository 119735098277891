import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import YouTubeEmbed from './utilityComponents/YoutubeEmbed';
import logo from '../../assets/images/opec10.png';
import useFetch from '../custom_hooks/useFetch';
import ResourceControlComponent from './utilityComponents/ResourceControl';
import LogoutButton from '../auth/Logout';

const VideoList = ({ currentVideoId, allVideos, combinedCourses, onVideoSelect, isDisabled }) => {
    const showTestNotification = () => {
        alert("Please take the test attached to this lesson first before moving to another lesson.");
    }

    return (
        <div className="video-list overflow-auto">
            {allVideos && allVideos.map(video => (
                <div key={video._id}>
                    <div
                        className={`video-item ${currentVideoId === video._id ? 'text-success' : 'text-secondary'} fw-bold cursor-pointer`}
                        onClick={isDisabled ? () => onVideoSelect(video) : showTestNotification}
                    >
                        <i className={`${currentVideoId === video._id ? 'fa fa-fw fa-check-circle' : 'fa fa-fw fa-video'}`}></i> 
                        {video.title}
                    </div>
                    <hr />
                </div>
            ))}

            {/* Uncomment and adjust for combinedCourses if needed */}
            {/* 
            {combinedCourses.map(course => (
                <React.Fragment key={course._id}>
                    <h6 className="text-muted mt-3">{course.title}</h6>
                    {course.videos.map(video => (
                        <div
                            key={video._id}
                            className={`video-item ${currentVideoId === video._id ? 'active-video' : ''}`}
                            onClick={() => onVideoSelect(video._id)}
                        >
                            {video.title}
                        </div>
                    ))}
                </React.Fragment>
            ))}
            */}
        </div>
    );
};


const LessonView = ({ renderView, userData, videoData }) => {
    const[dVideoData, setDVideoData] = useState(videoData)
    const [videoId, setVideoId] = useState(null);
    const [courseVideos, setCourseVideos] = useState([]);
    const [combinedCourses, setCombinedCourses] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [testTaken, setTestTaken] = useState(false)
    const { result, isResultPending, resultError } = useFetch(videoData ? `https://opecstat.com/opecstat_server/video/getRelatedVideos/${videoId}` : null);

    
    useEffect(() => {
        setIsLoading(false)
        if (videoData && videoData._id) {
            setDVideoData(videoData)
            setVideoId(videoData._id);
            setTestTaken(false)
        }

        // Check if the user has previously taken this quiz
        (async () => {
            try {
            const response = await fetch(
                `https://opecstat.com/opecstat_server/quiz/getQuizByVideoId/${videoData._id}`
            );
            const data = await response.json();
            console.log("The Data", JSON.stringify(response,null,2))
            if (response.ok) {
                console.log("Taken Data ",JSON.stringify(data,null,2))
            }else{
                setTestTaken(true)
            }
            } catch (error) {
            console.error('Error fetching previous quiz data:', error);
            }
        })();

        // Check if the user has previously taken this quiz
        (async () => {
            try {
            const response = await fetch(
                `https://opecstat.com/opecstat_server/quiz/getQuizByUserAndVideoId/${userData._id}/${videoData._id}`
            );
            const data = await response.json();
            if (response.ok && data.quizFoundStatus) {
                setTestTaken(true)
                console.log("Taken Data ",JSON.stringify(data,null,2))
            }
            } catch (error) {
            console.error('Error fetching previous quiz data:', error);
            }
        })();
        
    }, [videoData]);

    useEffect(() => {
        // Fetch main course videos and combined course videos when dVideoData is loaded
        if (dVideoData && dVideoData.courseVideos && dVideoData.combinedCourses) {
            setCourseVideos(dVideoData.courseVideos);
            setCombinedCourses(dVideoData.combinedCourses);
        }
    }, [dVideoData]);

    const handleVideoSelect = (selectedVideo) => {
        setIsLoading(true)
        //console.log(JSON.stringify(selectedVideo,null,2))
        try{
            setVideoId(selectedVideo._id);
            setDVideoData(selectedVideo)
            setTestTaken(false)
        }catch(e){
            console.log("e: ", e)
        }
        setIsLoading(false)
    };

    const handleNavClick = () =>{
        setIsLoading(true)
    }

    const handleTestTaken = (value) =>{
        setTestTaken(value)
    }

    const showTestNotification = () =>{
        alert("Please take the quiz attatched to this lesson first before moving to another lesson")
    }

    return (
        <>
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <Link to="/dashboard" className='btn btn-sm btn-primary'><span className='fa fa-fw fa-arrow-left'></span> Back to dashboard </Link><LogoutButton/>
            </div>
            <div className='mb-2'>
                {dVideoData && <h1 className="h3 mb-0 text-gray-800 fw-bold">{dVideoData.title.toUpperCase()}</h1>}
            </div>
            {
                dVideoData && 
                <div className='row mb-2'>
                    <div className='col-md-3'>
                        <ResourceControlComponent videoData={dVideoData} userData={userData} type="textbook" />
                    </div>
                    <div className='col-md-3'>
                        <ResourceControlComponent videoData={dVideoData} userData={userData} type="quiz" handleTestTaken={handleTestTaken} />
                    </div>
                    <div className='col-md-3'>
                        <ResourceControlComponent videoData={dVideoData} userData={userData} type="assignment" />
                    </div>
                    <div className='col-md-3'>
                        <ResourceControlComponent videoData={dVideoData} userData={userData} type="chat" />
                    </div>
                </div>
            }
            
            <div className='row'>
                <div className="col-xl-8 col-lg-8">
                    <div className="card shadow mb-4">
                        <div className="card-body">
                            <div className="milestone_area">
                                {
                                    userData.sub_status === 50?(
                                        <center><img src={logo} alt='logo' style={{ width: "30%", height: "25%", opacity: "25%" }} /><br /><b className='text-dark'>You are currently disenrolled and Cannot Particiapte in this program...<br/><br/>To resume your program: <br/><br/><span className='w-50'><ResourceControlComponent videoData={dVideoData} userData={userData} type="subscribe" /></span><br/><br/><span className='text-danger'>Kindly note that the subcribtion fee goes back to &#8358;67,000 soon</span></b></center>
                                    ):(
                                        <>
                                            {!dVideoData && <center><img src={logo} alt='logo' style={{ width: "30%", height: "25%", opacity: "25%" }} /><br /><b>Loading Lesson...</b></center>}
                                            {dVideoData && isLoading && <center><img src={logo} alt='logo' style={{ width: "30%", height: "25%", opacity: "25%" }} /><br /><b>Loading Lesson...</b></center>}
                                            {dVideoData && !isLoading && <YouTubeEmbed videoData={dVideoData} />}
                                            {dVideoData && userData.sub_status < 4 ? (
                                                <center className='mt-3'>
                                                    <div className='row'>
                                                        {
                                                            //testTaken?(
                                                                <>
                                                                    <div className='col-md-6'>
                                                                        <Link to={`/lesson/${dVideoData.prevVideo}`} onClick={()=>handleNavClick()} className={`w-100 btn btn-lg btn-dark ${!dVideoData.prevVideo ? 'disabled' : ''}`}>Prev.</Link>
                                                                    </div>
                                                                    <div className='col-md-6'>
                                                                        <Link to={`/lesson/${dVideoData.nextVideo}`} onClick={()=>handleNavClick()} className={`w-100 btn btn-lg btn-dark ${!dVideoData.nextVideo ? 'disabled' : ''}`}>Next</Link>
                                                                    </div>
                                                                </>
                                                            /*):(
                                                                
                                                                <>
                                                                    <div className='col-md-6'>
                                                                        <Link to={`/lesson/${dVideoData.prevVideo}`} onClick={()=>handleNavClick()} className={`w-100 btn btn-lg btn-dark ${!dVideoData.prevVideo ? 'disabled' : ''}`}>Prev.</Link>
                                                                    </div>
                                                                    <div className='col-md-6'>
                                                                        <button onClick={()=>showTestNotification()} className={`w-100 btn btn-lg btn-dark ${!dVideoData.nextVideo ? 'disabled' : ''}`}>Next</button>
                                                                    </div>
                                                                </>
                                                            //)
                                                        */}
                                                    </div>
                                                </center>
                                            ) : null}
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4">
                    <div className="card shadow mb-4">
                        <div className="card-header pb-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 className="m-0 font-weight-bold text-primary">Video Lessons</h6>
                        </div>
                        <div className="card-body" style={{height:"400px", overflow:"auto"}}>
                            {isResultPending && <center><img src={logo} alt='logo' style={{ width: "30%", height: "25%", opacity: "25%" }} /><br /><b>Loading Lesson List...</b></center>}
                            {  
                                result && <VideoList 
                                currentVideoId={videoId}
                                allVideos={result.orderedVideos}
                                combinedCourses={combinedCourses}
                                onVideoSelect={handleVideoSelect}
                                isDisabled={testTaken}
                                />
                            }
                            {
                                resultError && <center><img src={logo} alt='logo' style={{ width: "30%", height: "25%", opacity: "25%" }} /><br /><b>Loading Lesson List...</b></center>
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LessonView;
