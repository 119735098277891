import React, { useState, useEffect } from 'react';

const FlutterwaveSubscription = ({
  prefilledEmail = "",
  prefilledName = "",
  userId = "",
  amount,
  currency = "NGN",
  phoneNumber = "08139582196",
  publicKey = "FLWPUBK-3ac77063a52ca467ead928d3af1948f6-X",
  onSuccess,
  onFailure,
  logo = "https://opecstat.com/static/media/opec10.0ff41e7823a84d193d60.png",
  buttonDisplayText
}) => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: prefilledName,
    email: prefilledEmail,
  });

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.flutterwave.com/v3.js';
    script.async = true;
    script.onload = () => setIsScriptLoaded(true);
    script.onerror = () => alert('Failed to load payment gateway. Please try again later.');
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubscription = () => {
    if (!isScriptLoaded) {
      alert('Payment gateway not ready. Please wait and try again.');
      return;
    }

    const paymentData = {
      tx_ref: `tx-${Math.floor(Math.random() * 1000000)}`,
      amount,
      currency,
      email: formData.email,
      name: formData.name,
      custom_fields: [
        { display_name: formData.name, variable_name: "user_id", value: userId },
      ],
    };

    window.FlutterwaveCheckout({
      public_key: publicKey,
      tx_ref: paymentData.tx_ref,
      amount: paymentData.amount,
      currency: paymentData.currency,
      payment_options: "card, banktransfer, ussd",
      customer: {
        email: paymentData.email,
        phone_number: phoneNumber,
        name: paymentData.name,
      },
      customizations: {
        title: "Opecstat Academy",
        description: "Become a Certified Programmer",
        logo,
      },
      callback: (data) => {
        if (data.status === "successful") {
          fetch(`https://opecstat.com/opecstat_server/user/enrollbyEmail/${formData.email}/66e6f951c1eda0933df58864`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
          })
            .then((response) =>
              response.ok
                ? onSuccess?.("Subscription successful!")
                : onFailure?.("Failed to update subscription status.")
            )
            .catch((e) =>
              onFailure?.("An error occurred while enrolling you. Send a mail to mail@opecstat.com and it would be rectified")
            );
        } else {
          onFailure?.("Payment was not successful.");
        }
      },
      onclose: () => {
        console.log("Payment cancelled!");
      },
    });
  };

  const handleProceedToPayment = () => {
    if (!formData.name || !formData.email) {
      alert("Please provide your name and email.");
      return;
    }
    fetch('https://opecstat.com/opecstat_server/user/addUser', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name: formData.name, email: formData.email, country:"Nigeria", password:"default123" }),
      })
        .then((response) => {
          if(response.ok){
            setIsModalOpen(false);
            handleSubscription();
          }else{
            alert(response.data.error.message)
          }
        })
        .catch((e) =>
          //onFailure?.("An error occurred while updating your subscription.")
            console.log(e)
        );
    setIsModalOpen(false);
    handleSubscription();
  };

  const openModalOrProceed = () => {
    if (prefilledName && prefilledEmail) {
      handleSubscription();
    } else {
      setIsModalOpen(true);
    }
  };

  return (
    <div>
      <button className='btn btn-lg btn-success' onClick={openModalOrProceed} disabled={!isScriptLoaded}>
        {buttonDisplayText}
      </button>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3 className='fw-bold'>Enter Your Details</h3>
            <form>
              <div>
                <label style={{float:"left"}}>Name:</label>
                <input
                  type="text"
                  name="name"
                  className='form-control'
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label style={{float:"left"}}>Email:</label>
                <input
                  type="email"
                  name="email"
                  className='form-control'
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>
              <button type="button" className='btn btn-md btn-success mb-1' onClick={handleProceedToPayment}>
                Proceed to Payment
              </button><br/>
              <button
                type="button"
                className='btn btn-sm btn-outline'
                onClick={() => setIsModalOpen(false)}
                style={{ marginLeft: "10px" }}
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}

      <style jsx>{`
        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.6);
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .modal-content {
          background: white;
          padding: 20px;
          border-radius: 5px;
          width: 300px;
          text-align: center;
        }
        .modal-content input {
          width: 100%;
          margin-bottom: 10px;
          padding: 5px;
        }
        .modal-content button {
          padding: 10px 15px;
        }
      `}</style>
    </div>
  );
};

export default FlutterwaveSubscription;
