//import Navbar from "../components/Navbar";
//import Banner from "../components/Banner";
//import '../assets/styles/bootstrap.css';
//import '../assets/styles/style.css';
import '../assets/styles/landwind/input.css';
import '../assets/styles/landwind/output.css';
//import '../assets/styles/responsive.css';
//import '../assets/styles/font-awesome.min.css'
//import Features from "../components/Features";
//import HowItWorks from "../components/HowItWorks";
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from '../components/homepage/Navbar';
import Hero from '../components/homepage/Hero';
import Body from '../components/homepage/Body';
import '../assets/styles/font_style.css';
import loader1 from '../assets/images/a6b97ecb540e6e097a8aa3961ffe84de.gif'
import logo from '../assets/images/opec10.png'
import { useState } from 'react';



const Homepage = () =>{
    const [isPending, setIsPending] = useState(true)
   setTimeout(() => {
     setIsPending(false)
   }, 3000);
    return (
        <body>
            {isPending && <center className='h-100 text-xxl mt-10' ><br/><br/><img src={loader1} alt='loader'  style={{width:"250px", height:"200px"}} /><br/><img src={logo} alt='loader' style={{width:"250px", height:"250px"}} /><p className='fs-2' style={{color:"black",fontFamily:"Helvetica", fontWeight:"normal"}}>Welcome to Opecstat Academy...</p></center>}
            {
                !isPending &&
                <div className="hero_area">
                    <Navbar/>
                    <Hero/>
                </div>
            }
        </body>
    )
}

export default Homepage;