import React from 'react';

const YouTubeEmbed = ({ videoData }) => {
  const embedUrl = videoData.youtube_link//`https://www.youtube.com/embed/${videoData.youtube_link}`;

  return (
    <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}>
      <iframe
        title="YouTube video player"
        width="100%"
        height="100%"
        style={{ position: 'absolute', top: 0, left: 0 }}
        src={embedUrl}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
};

export default YouTubeEmbed;
