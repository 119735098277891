import React, { useEffect, useState } from 'react'
import defaultImage from '../../assets/images/userImage/defaultUserImage.png'
import LogoutButton from '../auth/Logout'
import image1 from "../../assets/images/client-1.jpg"
import image2 from "../../assets/images/client-2.jpg"
import image3 from "../../assets/images/images-2.jpeg"
import image4 from "../../assets/images/AdobeStock_829005055.jpeg"

const DashboardTopbar = ({userData, onProfileUpdate, renderView}) => {
    let link = null 
    if(userData.profileImage){
        link = `http://swaptime.co/public/images/userProfileImages/${userData.profileImage}`
    }
    const [profileImagePreview, setProfileImagePreview] = useState(link || defaultImage); 
   /* useEffect(() => {
        link = `http://swaptime.co/public/images/userProfileImages/${userData.profileImage}`
        setProfileImagePreview(link || defaultImage)
        onProfileUpdate()
    }, [userData]);*/
    return ( 
        <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

            {/*<!-- Sidebar Toggle (Topbar) -->*/}
            <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
                <i class="fa fa-bars"></i>
            </button>

            {/*<!-- Topbar Search -->
            <form
                class="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                <div class="input-group">
                    <input type="text" class="form-control bg-light border-0 small" placeholder="Find Swaps..."
                        aria-label="Search" aria-describedby="basic-addon2"/>
                    <div class="input-group-append">
                        <button onClick={()=>{renderView('view_offers')}} class="btn btn-primary" type="button">
                            <i class="fas fa-search fa-sm"></i>
                        </button>
                    </div>
                </div>
            </form>*/}

            {/*<!-- Topbar Navbar -->*/}
            <ul class="navbar-nav ml-auto">

                {/*<!-- Nav Item - Search Dropdown (Visible Only XS) -->*/}
                <li class="nav-item dropdown no-arrow d-sm-none">
                    <a class="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fas fa-search fa-fw"></i>
                    </a>
                    {/*<!-- Dropdown - Messages -->*/}
                    <div class="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
                        aria-labelledby="searchDropdown">
                        <form class="form-inline mr-auto w-100 navbar-search">
                            <div class="input-group">
                                <input type="text" class="form-control bg-light border-0 small"
                                    placeholder="Search for..." aria-label="Search"
                                    aria-describedby="basic-addon2"/>
                                <div class="input-group-append">
                                    <button class="btn btn-primary" type="button">
                                        <i class="fas fa-search fa-sm"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </li>

                {/*<!-- Nav Item - Alerts -->*/}
                <li class="nav-item dropdown no-arrow mx-1">
                    <a class="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fas fa-bell fa-fw"></i>
                        {/*<!-- Counter - Alerts -->*/}
                        <span class="badge badge-danger badge-counter">3+</span>
                    </a>
                    {/*<!-- Dropdown - Alerts -->*/}
                    <div class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                        aria-labelledby="alertsDropdown">
                        <h6 class="dropdown-header">
                            Alerts Center
                        </h6>
                        <a class="dropdown-item d-flex align-items-center" href="#">
                            <div class="mr-3">
                                <div class="icon-circle bg-primary">
                                    <i class="fas fa-file-alt text-white"></i>
                                </div>
                            </div>
                            <div>
                                <div class="small text-gray-500">August 12, 2024</div>
                                <span class="font-weight-bold">Alice Rewan Just responded to your swap Offer!</span>
                            </div>
                        </a>
                        <a class="dropdown-item d-flex align-items-center" href="#">
                            <div class="mr-3">
                                <div class="icon-circle bg-success">
                                    <i class="fas fa-donate text-white"></i>
                                </div>
                            </div>
                            <div>
                                <div class="small text-gray-500">August 13, 2024</div>
                                Alwan has recorded a milestone acheivment
                            </div>
                        </a>
                        <a class="dropdown-item d-flex align-items-center" href="#">
                            <div class="mr-3">
                                <div class="icon-circle bg-warning">
                                    <i class="fas fa-exclamation-triangle text-white"></i>
                                </div>
                            </div>
                            <div>
                                <div class="small text-gray-500">December 2, 2019</div>
                                Sign in alert: We noticed a Sign in from a new Device
                            </div>
                        </a>
                        <a class="dropdown-item text-center small text-gray-500" href="#">Show All Alerts</a>
                    </div>
                </li>

                {/*<!-- Nav Item - Messages -->*/}
                <li class="nav-item dropdown no-arrow mx-1">
                    <a class="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fas fa-envelope fa-fw"></i>
                        {/*<!-- Counter - Messages -->*/}
                        <span class="badge badge-danger badge-counter">7</span>
                    </a>
                    {/*<!-- Dropdown - Messages -->*/}
                    <div class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                        aria-labelledby="messagesDropdown">
                        <h6 class="dropdown-header">
                            Message Center
                        </h6>
                        <a class="dropdown-item d-flex align-items-center" href="#">
                            <div class="dropdown-list-image mr-3">
                                <img class="rounded-circle" src={image1}
                                    alt="..."/>
                                <div class="status-indicator bg-success"></div>
                            </div>
                            <div class="font-weight-bold">
                                <div class="text-truncate">Hi there! I am interested in your swap offer, Let's talk.</div>
                                <div class="small text-gray-500">Alice Rewan · 58m</div>
                            </div>
                        </a>
                        <a class="dropdown-item d-flex align-items-center" href="#">
                            <div class="dropdown-list-image mr-3">
                                <img class="rounded-circle" src={image2}
                                    alt="..."/>
                                <div class="status-indicator"></div>
                            </div>
                            <div>
                                <div class="text-truncate">I have delivered the photos as per your request</div>
                                <div class="small text-gray-500">Jae Chun · 1d</div>
                            </div>
                        </a>
                        <a class="dropdown-item d-flex align-items-center" href="#">
                            <div class="dropdown-list-image mr-3">
                                <img class="rounded-circle" src={image3}
                                    alt="..."/>
                                <div class="status-indicator bg-warning"></div>
                            </div>
                            <div>
                                <div class="text-truncate">Last month's report looks great, I am very happy with
                                    the progress so far, keep up the good work!</div>
                                <div class="small text-gray-500">Morgan Alvarez · 2d</div>
                            </div>
                        </a>
                        <a class="dropdown-item d-flex align-items-center" href="#">
                            <div class="dropdown-list-image mr-3">
                                <img class="rounded-circle" src={image4}
                                    alt="..."/>
                                <div class="status-indicator bg-success"></div>
                            </div>
                            <div>
                                <div class="text-truncate">let's make faster progress. We are gettig there...</div>
                                <div class="small text-gray-500">Charles · 2w</div>
                            </div>
                        </a>
                        <a class="dropdown-item text-center small text-gray-500" href="#">Read More Messages</a>
                    </div>
                </li>

                <div class="topbar-divider d-none d-sm-block"></div>

                {/*<!-- Nav Item - User Information -->*/}
                <li class="nav-item dropdown no-arrow">
                    <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="mr-2 d-none d-lg-inline text-gray-600 small">{userData.name}</span>
                        <img class="img-profile rounded-circle"
                            src={profileImagePreview}/>
                    </a>
                    {/*<!-- Dropdown - User Information -->*/}
                    <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                        aria-labelledby="userDropdown">
                        <a onClick={()=>{renderView('onboard')}} class="dropdown-item" href="#">
                            <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                            Profile
                        </a>
                        {/*<a class="dropdown-item" href="#">
                            <i class="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                            Settings
                        </a>
                        <a class="dropdown-item" href="#">
                            <i class="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                            Activity Log
                        </a>*/}
                        <div class="dropdown-divider"></div>
                        <center><LogoutButton/></center>
                    </div>
                </li>

            </ul>

        </nav>
     );
}
 
export default DashboardTopbar;